import { Injectable } from "@angular/core";
import { User } from "@overa/security";
import { Filters } from "../models/filters.model";
import { IGlobals } from "@overa/shared";

export class ActivityUser extends User {
  override defaultFilters?: DefaultFilters[];
  override currentGlobalFilters?: Filters;
  override filters?: Filters;

  tenantId?: string;
  override viewLinkFilter?: any;
}

export interface DefaultFilters {
  [key: string]: string | string[] | undefined;
  view?: string;
  filter?: string;
  value?: string | string[];
  type?: string;
}

@Injectable({
  providedIn: "root",
})
export class ActivityGlobals implements IGlobals {

  user: ActivityUser = new ActivityUser(); // = this.globals.user as ActivityUser;
  cache: { [key: string]: unknown } = {};

  //[key:string]: any; // = this.globals.cache;

  //constructor(private readonly globals: Globals) { }
}
