import { RoutesDefinition } from "@overa/base";

import { LayoutComponent } from "./components/layout.component";

import { UserSummaryComponent } from "./components/userActivity/userSummary/userSummary.component";
import { UserSummaryUrlComponent } from "./components/userActivity/userSummaryUrl/userSummaryUrl.component";
import { TelecommutingIndicatorsComponent } from "./components/indicators/telecommuting-indicators/telecommuting-indicators.component";
import { UserActivitySummary2WeeksComponent } from "./components/userActivity/user-activity-summary2-weeks/user-activity-summary2-weeks.component";
import { PercentageUsersTelemetryComponent } from "./components/audit/percentage-users-telemetry/percentageUsersTelemetry.component";

import { UserStatusSummaryOneLineComponent } from "./components/userActivity/userStatusSummaryOneLine/userStatusSummaryOneLine.component";
import { UserActivitySummaryGroupedComponent } from "./components/userActivity/user-activity-summary-grouped/user-activity-summary-grouped.component";
import { DetailedViewComponent } from "./components/userActivity/detailed-view/detailed-view.component";
import { UserActivitySummaryComponent } from "./components/userActivity/user-activity-summary/user-activity-summary.component";
import { DeviceProcessConsumptionComponent } from "./components/deviceActivity/device-process-consumption/device-process-consumption.component";

import { Injectable } from "@angular/core";
import { DigitalDisconnectComponent } from "./components/indicators/digitalDisconnect/digitalDisconnect.component";
import { DeviceSignalReportComponent } from "./components/signals/device-signal-report/device-signal-report.component";
import { ProductiveUsageComponent } from "./components/indicators/productive-usage/productive-usage.component";
import { EntryExitComponent } from "./components/indicators/entry-exit/entry-exit.component";
import { ProductiveWeekComponent } from "./components/indicators/productive-week/productive-week.component";
import { ProductiveVsAfkComponent } from "./components/indicators/productive-vs-afk/productive-vs-afk.component";
import { AppUrlScheduleComponent } from "./components/userActivity/app-url-schedule/app-url-schedule.component";
import { ProcessConsumptionComponent } from "./components/deviceActivity/process-consumption/process-consumption.component";
import { TerminalComponent } from "./components/deviceActivity/terminal/terminal.component";
import { DeviceCommandsComponent } from "./components/deviceActivity/device-commands/device-commands.component";

import { AppModule } from "./app.module";
import { GoldenLayoutComponentService } from "./components/golden-layout/golden-layout-component.service";
import { LayoutResolver } from "./utils/layout.resolver";
import { HomeComponent } from "./components/home/home.component";
import { Routes } from "@angular/router";
import { logLog } from "@overa/shared";
import { NGRouteInfo } from "./components/golden-layout/golden-layout-utils";
import { AuthGuard } from "@overa/security";
import { SignalDashboardComponent } from "./components/signals/signalDashboard/signalDashboard.component";


/**
 * Creates a dynamic route loader for Angular modules.
 *
 * This function returns an object with a `path` and a `loadChildren` property.
 * The `loadChildren` property is a function that dynamically imports the specified module
 * and returns the appropriate module based on the provided path.
 *
 * @param path - The path for the route.
 * @param moduleImport - A function that returns a promise resolving to the module to be loaded.
 * @returns An object containing the `path` and `loadChildren` properties.
 *
 * The `loadChildren` function performs the following:
 * - Imports the module using the provided `moduleImport` function.
 * - Determines the routes to be used based on the provided `path`.
 * - Logs the loading of dynamic routes.
 * - Registers component types with the `GoldenLayoutComponentService`.
 * - Returns the appropriate module based on the provided `path`.
 *
 * Supported paths and their corresponding modules:
 * - 'dynamic': Returns `ActivityDynamicModule`.
 * - 'dynamicMaintenances': Returns `OveraDynamicMaintenancesRoutingModule`.
 * - 'm': Returns `ActivityDynamicFormModule`.
 * - 'core': Returns `LibRoutingModule`.
 * - Default: Returns `null`.
 */
function createDynamicRouteLoader(
  path: string,
  moduleImport: () => Promise<any>
) {
  return {
    path,
    loadChildren: () =>
      moduleImport().then((m) => {

        let routes;
        switch (path) {
          case 'dynamic':
            routes = m.OVERA_DYNAMIC_ROUTES;
            break;
          case 'dynamicMaintenances':
            routes = m.DYNAMIC_MAINTENANCE_ROUTES;
            break;
          case 'm':
            routes = m.OVERA_DYNAMIC_FORM_ROUTES;
            break;
          case 'core':
            {
              routes = m.NOTIFICATION_ROUTES.concat(m.SECURITY_ROUTES).concat(m.BASE_ROUTES);
              // Disable Golden Layout for the profileLogOut route. Produces an logout loop on GL init loading previous state.
              //const route = routes.find((r: any) => r.path === 'profileLogOut');
              const route = findRouteByPath(routes, 'profileLogOut');
              if (route) {
                if (!route.data) {
                  route.data = {};
                }
                route.data.useGoldenLayout = false;
              }
            }
            break;
          default:
            routes = [];
        }

        logLog(`GL - Loading dynamic routes: ${path}`);

        const glService = AppModule.injector.get(GoldenLayoutComponentService);

        const gl_components = getComponentsRecursively(routes, path);

        glService.registerComponentTypes(gl_components);

        switch (path) {
          case 'dynamic':
            return m.ActivityDynamicModule;
          case 'dynamicMaintenances':
            return m.OveraDynamicMaintenancesRoutingModule;
          case 'm':
            return m.ActivityDynamicFormModule;
          case 'core':
            return m.LibRoutingModule;
          default:
            return null;
        }
      }),
  };
}

function findRouteByPath(routes: any[], path: string): any | null {
  for (const route of routes) {
    if (route.path === path) {
      return route;
    }
    if (route.children) {
      const found = findRouteByPath(route.children, path);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

/**
 * Recursively retrieves component information from a set of routes.
 *
 * @param routes - An array of route objects to process.
 * @param parentPath - The base path to prepend to each route's path. Defaults to an empty string.
 * @returns An array of NGRouteInfo objects containing information about each route's component.
 *
 * @interface NGRouteInfo
 * @property {string} name - The full path of the route.
 * @property {any} componentType - The component associated with the route.
 * @property {any} data - Additional data associated with the route.
 * @property {string[]} params - An array of parameter names extracted from the route's path.
 */
function getComponentsRecursively(routes: Routes, parentPath: string = ''): NGRouteInfo[] {
  let components: NGRouteInfo[] = [];

  routes.forEach(route => {

    const currentPath = parentPath ? `${parentPath}/${route.path}`.replace(/\/+/g, '/') : route.path;

    if (currentPath && currentPath !== '' && route.component) {

      const params = route.path!.match(/:[^/]+/g)?.map(param => param.substring(1)) || [];

      if (!route.data || (route.data && route.data["useGoldenLayout"] !== false)) {
        components.push({
          name: currentPath,
          componentType: route.component,
          data: route.data,
          params: params
        });
      }
    }

    if (route.children && route.children.length > 0) {
      const childrenPaths = getComponentsRecursively(route.children, currentPath);
      components = components.concat(childrenPaths);
    }
  });

  return components;

}


@Injectable({
  providedIn: 'root'
})
export class RoutesService {

  getRoutesDefinition(): RoutesDefinition {
    return {
      routes: [
        {
          path: "",
          pathMatch: "prefix",
          component: LayoutComponent,
          canActivate: [AuthGuard],
          canActivateChild: [AuthGuard],
          resolve: { layout: LayoutResolver },
          children: [
            {
              path: "", component: HomeComponent,
              data: { useGoldenLayout: false }
            },
            {
              path: "view",
              component: DetailedViewComponent,
              pathMatch: "full",
            },
            {
              path: 'ifrdash/:parametro',
              component: SignalDashboardComponent,
              pathMatch: "full",
            },
            {
              path: "deviceActivity",
              children: [
                {
                  path: "deviceProcessConsumption",
                  component: DeviceProcessConsumptionComponent,
                },
              ],
            },
            {
              path: "hrPointers/digitalDisconnect",
              component: DigitalDisconnectComponent,
              pathMatch: "full",
            },
            {
              path: "userActivity",
              children: [
                {
                  path: "telecommutingIndicators",
                  component: TelecommutingIndicatorsComponent,
                },
                {
                  path: "userActivitySummary",
                  component: UserActivitySummaryComponent,
                },
                {
                  path: "userStatusSummaryOneLine",
                  component: UserStatusSummaryOneLineComponent,
                },
                {
                  path: "userSummary",
                  component: UserSummaryComponent,
                },
                {
                  path: "userActivitySummaryUrl",
                  component: UserSummaryUrlComponent,
                },
                {
                  path: "userActivitySummaryGrouped",
                  component: UserActivitySummaryGroupedComponent,
                },
                {
                  path: "userActivity2WeeksComponent",
                  component: UserActivitySummary2WeeksComponent,
                },
                {
                  path: "percentageUsersTelemetryComponent",
                  component: PercentageUsersTelemetryComponent,
                },
                {
                  path: "appUrlShedule",
                  component: AppUrlScheduleComponent,
                },
              ],
            },
            {
              path: "deviceActivity",
              children: [
                {
                  path: "deviceProcessConsumption",
                  component: DeviceProcessConsumptionComponent,
                },
                {
                  path: "processConsumption",
                  component: ProcessConsumptionComponent,
                },
                {
                  path: "terminal",
                  component: TerminalComponent,
                },
                {
                  path: "deviceCommands",
                  component: DeviceCommandsComponent,
                },
              ],
            },
            {
              path: "hrPointers",
              children: [
                {
                  path: "digitalDisconnect",
                  component: DigitalDisconnectComponent,
                },
                {
                  path: "productiveUsage",
                  component: ProductiveUsageComponent,
                },
                {
                  path: "inputOutputRanges",
                  component: EntryExitComponent,
                },
                {
                  path: "productiveWeek",
                  component: ProductiveWeekComponent,
                },
                {
                  path: "productiveVsAfk",
                  component: ProductiveVsAfkComponent,
                },
              ],
            },
            {
              path: "signalAnalisys",
              children: [
                {
                  path: "deviceSignalReport",
                  component: DeviceSignalReportComponent,
                },
              ],
            },
            createDynamicRouteLoader(
              'dynamic',
              () => import('./modules/activity-dynamic.module')
            ),
            createDynamicRouteLoader(
              'dynamicMaintenances',
              () => import('@overa/dynamic/components')
            ),
            createDynamicRouteLoader(
              'm',
              () => import('./modules/activity-dynamic-form.module')
            ),
            createDynamicRouteLoader(
              'core',
              () => import('@overa/base')
            ),
          ]
        }
      ]
    };
  }
}

export class RoutesSettings {
  private static routesService: RoutesService;

  public static initialize(routesService: RoutesService) {
    RoutesSettings.routesService = routesService;
  }

  public static get ROUTES(): Routes {
    if (!RoutesSettings.routesService) {
      return [];
    }

    return RoutesSettings.routesService.getRoutesDefinition().routes || [];
  }


  /**
   * Retrieves the components from the menu service's menu definition.
   * If the menu service is not available, returns an empty array.
   * 
   * @returns {Routes} An array of routes representing the components.
   */
  public static get Components(): NGRouteInfo[] {
    if (!RoutesSettings.routesService) {
      return [];
    }
    const routes = RoutesSettings.routesService.getRoutesDefinition().routes || [];
    return getComponentsRecursively(routes, '');
  }
}
