import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConfigsService, logLog } from '@overa/shared';
import { LayoutModeService } from '../services/layout-mode.service';


@Injectable({
    providedIn: 'root',
})
/**
 * The `LayoutResolver` class is responsible for resolving the layout mode for a given route.
 * It determines whether to use Golden Layout or Router-Outlet based on the route data or configuration.
 */
export class LayoutResolver {
    constructor(
        private readonly config: ConfigsService,
        private readonly layoutModeService: LayoutModeService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): void {

        console.log(`LAYOUT RESOLVER - HANDLE ROUTE`, route);

        const configGoldenLayout: boolean = this.config.getModuleConfig(
            "overa-shared",
            "useGoldenLayout",
            false
        );
        const useGoldenLayout = route.data?.['useGoldenLayout'] || configGoldenLayout;

        if (useGoldenLayout) {
            this.layoutModeService.setGoldenLayoutMode(true);
            console.log(`LAYOUT-RESOLVER - Loading [${route.routeConfig?.path}] in Golden Layout`);
        } else {
            this.layoutModeService.setGoldenLayoutMode(false);
            console.log(`LAYOUT-RESOLVER - Loading [${route.routeConfig?.path}] in Router-Outlet`);
        }
    }
}
