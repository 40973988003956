import { InjectionToken } from "@angular/core";
import { ComponentContainer, ItemType, LayoutConfig, Side } from "golden-layout";

/**
 * The initial layout configuration for Golden Layout.
 * 
 * @constant
 * @type {LayoutConfig}
 * 
 * @property {object} root - The root configuration of the layout.
 * @property {ItemType} root.type - The type of the root item, which is a stack.
 * @property {Array} root.content - The content of the root item, initially empty.
 * 
 * @property {object} header - The header configuration of the layout.
 * @property {Side} header.show - The side where the header is shown, which is at the top.
 * @property {boolean} [header.popout=false] - Whether the popout option is enabled.
 * 
 * @property {object} settings - The settings configuration of the layout.
 * @property {string} settings.responsiveMode - The responsive mode, set to "always".
 * @property {boolean} settings.constrainDragToContainer - Whether dragging is constrained to the container.
 * @property {boolean} settings.reorderEnabled - Whether reordering is enabled.
 * @property {boolean} [settings.popoutWholeStack=false] - Whether the whole stack can be popped out.
 * @property {boolean} settings.blockedPopoutsThrowError - Whether blocked popouts throw an error.
 * @property {boolean} settings.closePopoutsOnUnload - Whether popouts are closed on unload.
 * 
 * @property {object} dimensions - The dimensions configuration of the layout.
 * @property {number} dimensions.borderWidth - The width of the border, set to 5.
 * @property {number} dimensions.minItemHeight - The minimum height of an item, set to 200.
 * @property {number} dimensions.minItemWidth - The minimum width of an item, set to 300.
 * @property {number} dimensions.headerHeight - The height of the header, set to 30.
 * @property {number} dimensions.dragProxyWidth - The width of the drag proxy, set to 300.
 * @property {number} dimensions.dragProxyHeight - The height of the drag proxy, set to 200.
 */
export const GL_INITIAL_LAYOUT: LayoutConfig = {
    root: {
        type: ItemType.stack,
        content: [],
    },
    header: {
        show: Side.top,
        //close: false, //if false break drag and close last stack tab
        popout: false,
    },
    settings: {
        //hasHeaders: true,
        responsiveMode: "always",
        constrainDragToContainer: true,
        reorderEnabled: true,
        //selectionEnabled: true,
        popoutWholeStack: false,
        blockedPopoutsThrowError: true,
        closePopoutsOnUnload: true,
        //showPopoutIcon: false,
        //showMaximiseIcon: true,
        //showCloseIcon: false,
    },
    /* labels: {
      popout: 'Abrir en nueva ventana',
      minimise: 'Minimizar',
      maximise: 'Maximizar',
      close: 'Cerrar'
    }, */
    dimensions: {
        borderWidth: 5,
        minItemHeight: 200,
        minItemWidth: 300,
        headerHeight: 30,
        dragProxyWidth: 300,
        dragProxyHeight: 200,
    },
};

export const GL_INITIAL_LAYOUT_NO_TABS: LayoutConfig = {
    ...GL_INITIAL_LAYOUT,
    header: { ...GL_INITIAL_LAYOUT.header, show: false }
}

/**
 * A constant representing the key used to store the state of the Golden Layout in the local storage.
 * This key is used to save and retrieve the layout state, ensuring the user's layout preferences are preserved.
 */
export const GL_LOCALSTORAGE_STATE = "GLState";


/**
 * Injection token used to provide a `ComponentContainer` instance for Golden Layout.
 * 
 * This token can be used to inject a `ComponentContainer` into Angular components or services
 * that require access to the Golden Layout container.
 * 
 * @constant
 * @type {InjectionToken<ComponentContainer>}
 */
export const GL_CONTAINER_INJECTION_TOKEN = new InjectionToken<ComponentContainer>('GoldenLayoutContainer');