import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
/**
 * Service to manage the layout mode of the application.
 * It allows switching between Angular Router and Golden Layout modes.
 */
export class LayoutModeService {
    /**
     * Subject to emit the current layout mode.
     * `false` indicates Angular Router mode, `true` indicates Golden Layout mode.
     */
    private readonly isGoldenLayoutSubject = new ReplaySubject<boolean>(1);

    /**
     * Observable to subscribe to the current layout mode.
     */
    isGoldenLayout$ = this.isGoldenLayoutSubject.asObservable();

    /**
     * Stores the current layout mode.
     */
    private currentValue: boolean = false;

    /**
     * Sets the layout mode.
     * @param isGolden - `true` to set Golden Layout mode, `false` to set Angular Router mode.
     */
    setGoldenLayoutMode(isGolden: boolean) {
        if (this.currentValue === isGolden) {
            return;
        }
        this.isGoldenLayoutSubject.next(isGolden);
        this.currentValue = isGolden;
    }

    /**
     * Gets the current layout mode.
     * @returns `true` if Golden Layout mode is active, `false` if Angular Router mode is active.
     */
    getGoldenLayoutMode(): boolean {
        return this.currentValue;
    }
}
