<div class="noGoldenLayout-2">

   <div class="no-responsive-home-map centered-2">
      <img src="assets/img/home/soluciones_overa_activity_ozona_tech.avif" alt="Delegaciones de Ozona Tech"
         title="Delegaciones de Ozona Tech" name="overa_activity" border="0" usemap="#Map" />

      <map name="Map" id="Map">
         <area shape="rect" coords="1090, 900, 1460, 1100" alt="Servicios Gestionados" title="Servicios Gestionados"
            (click)="navigationClick($event,'Servicios Gestionados')"
            onmouseover="MM_swapImage('overa_activity', '', 'assets/img/home/soluciones_overa_activity_ozona_tech-servicios_gestionados.avif', 1)"
            onmouseout="MM_swapImgRestore()" />
         <area shape="rect" coords="1210, 607, 1440, 794" alt="RRHH" title="RRHH"
            (click)="navigationClick($event,'RRHH')"
            onmouseover="MM_swapImage('overa_activity', '', 'assets/img/home/soluciones_overa_activity_ozona_tech-rrhh.avif', 1)"
            onmouseout="MM_swapImgRestore()" />
         <area shape="rect" coords="1170, 352, 1400, 520" alt="Seguridad" title="Seguridad"
            (click)="navigationClick($event,'Seguridad')"
            onmouseover="MM_swapImage('overa_activity', '', 'assets/img/home/soluciones_overa_activity_ozona_tech-seguridad.avif', 1)"
            onmouseout="MM_swapImgRestore()" />
         <area shape="rect" coords="205, 810, 420, 1000" alt="TI" title="TI" (click)="navigationClick($event,'TI')"
            onmouseover="MM_swapImage('overa_activity', '', 'assets/img/home/soluciones_overa_activity_ozona_tech-ti.avif', 1)"
            onmouseout="MM_swapImgRestore()" />
         <area shape="rect" coords="50, 60, 280, 240" alt="Negocio" title="Negocio"
            (click)="navigationClick($event,'Negocio')"
            onmouseover="MM_swapImage('overa_activity', '', 'assets/img/home/soluciones_overa_activity_ozona_tech-negocio.avif', 1)"
            onmouseout="MM_swapImgRestore()" />
         <area shape="rect" coords="1160, 152, 1450, 320" alt="Proyectos" title="Proyectos"
            (click)="navigationClick($event,'Proyectos')"
            onmouseover="MM_swapImage('overa_activity', '', 'assets/img/home/soluciones_overa_activity_ozona_tech-proyectos.avif', 1)"
            onmouseout="MM_swapImgRestore()" />
         <area shape="rect" coords="635, 1, 855, 181" alt="UX" title="UX" (click)="navigationClick($event,'UX')"
            onmouseover="MM_swapImage('overa_activity', '', 'assets/img/home/soluciones_overa_activity_ozona_tech-ux.avif', 1)"
            onmouseout="MM_swapImgRestore()" />
         <area shape="rect" coords="65, 530, 285, 700" alt="Assessment" title="Assessment"
            (click)="navigationClick($event,'Assessment')"
            onmouseover="MM_swapImage('overa_activity', '', 'assets/img/home/soluciones_overa_activity_ozona_tech-assessment.avif', 1)"
            onmouseout="MM_swapImgRestore()" />
      </map>
   </div>

   <div class="responsive-home-map">
      <div class="container">
         <div class="row">
            <div class="col-6 mt-5 mb-5">
               <a title="UX" href="/ux.html">
                  <img class="btn-image" src="assets/img/home/btn_ux-appm.avif" alt="UX" />
               </a>
            </div>
            <div class="col-6 mt-5 mb-5">
               <a title="Negocio" href="/negocio.html">
                  <img class="btn-image" src="assets/img/home/btn_negocio.avif" alt="Negocio" />
               </a>
            </div>
            <div class="col-6 mt-5 mb-5">
               <a title="Assessment" href="/assessment.html">
                  <img class="btn-image" src="assets/img/home/btn_assessment.avif" alt="Assessment" />
               </a>
            </div>
            <div class="col-6 mt-5 mb-5">
               <a title="TI" href="/ti.html">
                  <img class="btn-image" src="assets/img/home/btn_ti.avif" alt="TI" />
               </a>
            </div>
            <div class="col-6 mt-5 mb-5">
               <a title="Proyectos y Procesos" href="/proyectos-y-procesos.html">
                  <img class="btn-image" src="assets/img/home/btn_proyectos-y-procesos.avif"
                     alt="Proyectos y Procesos" />
               </a>
            </div>
            <div class="col-6 mt-5 mb-5">
               <a title="Seguridad" href="/seguridad.html">
                  <img class="btn-image" src="assets/img/home/btn_seguridad.avif" alt="Seguridad" />
               </a>
            </div>
            <div class="col-6 mt-5 mb-5">
               <a title="RRHH" href="/rrhh.html">
                  <img class="btn-image" src="assets/img/home/btn_rrhh.avif" alt="RRHH" />
               </a>
            </div>
            <div class="col-6 mt-5 mb-5">
               <a title="Servicios Gestionados CAU" href="/servicios-gestionados-cau-html">
                  <img class="btn-image" src="assets/img/home/btn_servicios-gestionados-cau.avif"
                     alt="Servicios Gestionados CAU" />
               </a>
            </div>
         </div>
      </div>
   </div>
</div>