<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <mat-card-content>
                            <div class="container-fluid main-container">

                                <div class="container-individualFilters">
                                    <app-filters #filterCmp [filterInit]=filterInit (loadData)=loadData($event)
                                        (filterChange)=changeFilter($event)
                                        (validAllRequiredFilters)=validAllRequiredFilters($event)>
                                    </app-filters>
                                </div>

                                <div class="row mt-5 mb-5">
                                    <div class="col-12">
                                        <overa-datatable #datatable [columns]="columns" [expandableRows]="true"
                                            [expandableColumns]="secondaryColumns"
                                            [loadDataFunction]="$any(loadDataFunction)"
                                            [expandableFirstDataKey]="'indicators'" [expandableProperty]="'indicators'"
                                            [rowGroupMode]="'rowspan'" title="Resumen Actividad" [showPaginator]="true"
                                            [showGlobalFilter]="false" [rowsPerPage]="[10, 20]" [showExports]="false"
                                            [items]="data" [totalRecords]="totalRecords"
                                            (pageChange)="onPageChange($event)">
                                        </overa-datatable>
                                    </div>
                                </div>

                            </div>
                        </mat-card-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>