import { Component, ElementRef, Inject, OnInit } from "@angular/core";
import { BaseComponentDirective, GLOBALS_TOKEN, IGlobals } from "@overa/shared";
import * as imageMapResize from "image-map-resizer";
import { OveraMessagesService } from "@overa/components/info-dialog";
import { ActivityGoldenLayoutService } from "src/app/services/activity-golden-layout.service";
import { GoldenLayoutManagerService } from "../golden-layout/golden-layout-manager.service";

@Component({
  selector: "app-home",
  templateUrl: "home.component.html",
  styleUrls: ["./home.scss"],
})
export class HomeComponent extends BaseComponentDirective implements OnInit {

  constructor(
    private readonly activityGoldenLayoutService: ActivityGoldenLayoutService,
    private readonly goldenLayoutManagerService: GoldenLayoutManagerService,
    private readonly overaMessagesService: OveraMessagesService,
    @Inject(GLOBALS_TOKEN) private readonly globals: IGlobals,
    elRef: ElementRef
  ) {
    super(elRef.nativeElement);
  }

  ngOnInit() {
    imageMapResize();
  }

  navigationClick(event: MouseEvent, route: string) {
    event.preventDefault();

    this.activityGoldenLayoutService.getUserLayouts(this.globals.user.id ?? "").subscribe(res => {
      const homeSelectionLayout = res.items.find(i => i.name.toLowerCase() == route.toLowerCase());
      if (homeSelectionLayout) {
        this.goldenLayoutManagerService.loadSavedLayout(homeSelectionLayout.value);
      }
      else {
        this.overaMessagesService.showToast(
          `No existe layout ${route.toLowerCase()}`,
          "Cree un layout con el nombre de la sección",
          "warning",
          ""
        );
      }
    });

  }
}
