<div>
    <div *ngIf="useDynamicFilters">
        <div class="filters-container">

            <div *ngFor="let variable of variables" class="filter-item">
                <label>{{ variable.name }}</label>
                <p-multiSelect *ngIf="variable.multi" [options]="variable.options" [(ngModel)]="variable.selectedValues"
                    optionLabel="text">
                </p-multiSelect>
                <p-dropdown *ngIf="!variable.multi" [options]="variable.options" [(ngModel)]="variable.selectedValue"
                    optionLabel="text">
                </p-dropdown>
            </div>
            <!-- Sección para filtros Ad-Hoc -->
            <div *ngFor="let filter of adhocFilters; let i = index" class="filter-item">
                <div style="display: flex; align-items: center; margin-bottom: 10px;">
                    <!-- Clave del filtro -->
                    <label style="margin-right: 10px;">{{ filter.key }}</label>

                    <!-- Operador del filtro -->
                    <p-dropdown [options]="filter.operators" [(ngModel)]="filter.selectedOperator" optionLabel="value"
                        optionValue="value">
                    </p-dropdown>
                    <!-- Valor del filtro -->
                    <input type="text" [(ngModel)]="filter.selectedValue" placeholder="Ingrese un valor" />

                    <!-- Botón para eliminar el filtro -->
                    <button pButton type="button" label="Eliminar" icon="pi pi-trash" class="p-button-danger"
                        (click)="removeFilter(i)">
                    </button>
                </div>
            </div>
            <button pButton type="button" label="Aplicar Filtros" (click)="applyFilters()"></button>
        </div>
    </div>
    <div class="iframeContainer" #iframeContainer></div>
</div>