import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
//import { Department } from "../models/bussinesOrganization/department.model";
import { ConfigDefaultFilters } from "../models/configDefaultFilters.model";
//import { Employee } from "../models/employees/employee.model";
import { FilterInstance, Filters } from "../models/filters.model";
import { GeneralData } from "../models/generalData.model";
//import { InfoView } from "../models/infoView.model";
//import { Category } from "../models/software/aplications/category.model";
import { BaseService, ConfigsService } from "@overa/shared";
import { FiltersService } from "./filters.service";
import { capitalize } from "lodash";
import { IGeneralData } from "../models/interfaces/iGeneralData.model";

@Injectable({ providedIn: "root" })
export class GeneralDataService extends BaseService<any> {
  [key: string]: any;
  override get apiBaseUrl(): string {
    return this.config.getModuleConfig("overa-security", "apiBaseUrl") + "/api";
  }

  override get path(): string {
    return "/Filter/";
  }

  private get generalDataApiUrl(): string {
    return this.apiBaseUrl + this.path;
  }

  constructor(
    public override http: HttpClient,
    protected override config: ConfigsService,
    private filtersService: FiltersService
  ) {
    super(http, config);
  }
  private adjustFilters(filters: Filters): Filters {
    const adjusted: { [key: string]: any } = {};
    for (const key in filters) {
      const value = filters[key];
      if (Array.isArray(value)) {
        adjusted[key] = value.map((item) =>
          typeof item === "object" && item !== null ? item.id : item
        );
      } else if (typeof value === "object" && value !== null) {
        if (value instanceof Date) {
          adjusted[key] = value.toISOString();
        } else {
          if (value.id === "true") {
            adjusted[key] = true;
          } else if (value.id === "false") {
            adjusted[key] = false;
          } else if (value.id === null || value.id === "null") {
            adjusted[key] = null;
          } else {
            adjusted[key] = value.id ? [value.id] : value;
          }
        }
      } else {
        adjusted[key] = value;
      }
    }
    return adjusted;
  }

  public getAdjustedFilters(filters: Filters): Filters {
    return this.adjustFilters(filters);
  }
  // Método privado para preparar los parámetros de la solicitud
  private getHttpParams(filters: Filters): HttpParams {
    let params = new HttpParams();
    const adjustedFilters = this.adjustFilters(filters);

    for (const key in adjustedFilters) {
      if (adjustedFilters[key] !== null && adjustedFilters[key] !== undefined) {
        if (Array.isArray(adjustedFilters[key])) {
          adjustedFilters[key].forEach(
            (item: any) => (params = params.append(key, item))
          );
        } else {
          params = params.append(key, adjustedFilters[key]);
        }
      }
    }

    return params;
  }

  // Método genérico para obtener datos filtrados
  private getFilteredData<T>(
    endpoint: string,
    filters: Filters
  ): Observable<T[]> {
    const params = this.getHttpParams(filters);

    return this.http.get<T[]>(`${this.generalDataApiUrl}${endpoint}`, {
      params,
    });
  }

  get_hosts(filters: Filters): Observable<GeneralData[]> {
    /* return this.http.post<GeneralData[]>(
      `${this.generalDataApiUrl}/Hosts`,
      filters
    ); */
    const results = [
      {
        id: "black-w10",
        name: "black-w10",
      },
      {
        id: "black-w11",
        name: "black-w11",
      },
      {
        id: "black-ws2016",
        name: "black-ws2016",
      },
    ];
    return of(results);
  }

  get_uids(filters: Filters): Observable<GeneralData[]> {
    /* return this.http.post<GeneralData[]>(
      `${this.generalDataApiUrl}/Uids`,
      filters
    ); */
    return of([{ id: "yyqlrxs8guopi1riz1ub9", name: "yyqlrxs8guopi1riz1ub9" }]);
  }

  get_users(filters: Filters): Observable<GeneralData[]> {
    /* return this.http.post<GeneralData[]>(
      `${this.generalDataApiUrl}/Users`,
      filters
    ); */
    return of([
      {
        id: "administrador",
        name: "Usuario 41 (Admin)",
      },
      {
        id: "administrator",
        name: "administrator",
      },
    ]);
  }

  get_deviceTypes(filters: Filters): Observable<GeneralData[]> {
    /* return this.http.post<GeneralData[]>(
      `${this.generalDataApiUrl}/DeviceTypes`,
      filters
    ); */
    return of([
      { id: "pc", name: "PC" },
      { id: "mobile", name: "Mobile" },
    ]);
  }

  get_types(filters: Filters): Observable<GeneralData[]> {
    /* return this.http.post<GeneralData[]>(
      `${this.generalDataApiUrl}/Types`,
      filters
    ); */
    return of([
      {
        id: "afk",
        name: "afk",
      },
      {
        id: "alive",
        name: "alive",
      },
    ]);
  }

  get_status(): Observable<GeneralData[]> {
    //return this.http.get<GeneralData[]>(`${this.generalDataApiUrl}/Status`);
    return of([
      {
        id: "default",
        name: "Default",
      },
      {
        id: "enabled",
        name: "Enabled",
      },
      {
        id: "disabled",
        name: "Disabled",
      },
      {
        id: "pending",
        name: "Pending",
      },
    ]);
  }

  get_applyTypes(): Observable<GeneralData[]> {
    return this.http.get<GeneralData[]>(
      `${this.generalDataApiUrl}/GetApplyTypes`
    );
  }

  get_configurationTypes(): Observable<GeneralData[]> {
    return this.http.get<GeneralData[]>(
      `${this.generalDataApiUrl}/ConfigurationTypes`
    );
  }

  get_productivityTypes(filters: Filters): Observable<GeneralData[]> {
    /* return this.http.post<GeneralData[]>(
      `${this.generalDataApiUrl}/ProductivityTypes`,
      filters
    ); */
    const results = [
      {
        id: "PRODUCTIVE",
        name: "PRODUCTIVE",
      },
      {
        id: "NOT_PRODUCTIVE",
        name: "NOT_PRODUCTIVE",
      },
      {
        id: "ALL",
        name: "ALL",
      },
    ];
    return of(results);
  }

  get_measurementProcessedTypes(): Observable<GeneralData[]> {
    /* return this.http.get<GeneralData[]>(
      `${this.generalDataApiUrl}/MeasurementProcessedTypes`
    ); */
    return of([
      {
        id: "activityindicators",
        name: "ActivityIndicators",
      },
      {
        id: "collaborativemeasurement",
        name: "CollaborativeMeasurement",
      },
    ]);
  }

  get_dayTypes(filters: Filters): Observable<GeneralData[]> {
    /* return this.http.post<GeneralData[]>(
      `${this.generalDataApiUrl}/DayTypes`,
      filters
    ); */
    return of([
      {
        name: "Laborable",
        color: "#afb7ec",
        requireValidation: null,
        computable: true,
        telecommuting: null,
        hoursNumber: null,
        tenantId: "7b108135-7283-4639-00a0-08d87f1cd182",
        id: "2e15471b-6622-47a0-92b0-08d90ae1fedc",
      },
      {
        name: "No laborable",
        color: "#cccccc",
        requireValidation: null,
        computable: null,
        telecommuting: null,
        hoursNumber: null,
        tenantId: "7b108135-7283-4639-00a0-08d87f1cd182",
        id: "8cf9901a-e2bf-4dba-92b1-08d90ae1fedc",
      },
    ]);
  }

  get_appVersions(filters: Filters): Observable<GeneralData[]> {
    /* return this.http.post<GeneralData[]>(
      `${this.generalDataApiUrl}/AppVersions`,
      filters
    ); */
    return of([
      {
        id: "0.14.19",
        name: "0.14.19",
      },
      {
        id: "0.14.35",
        name: "0.14.35",
      },
    ]);
  }

  get_deviceModels(filters: Filters): Observable<GeneralData[]> {
    /* return this.http.post<GeneralData[]>(
      `${this.generalDataApiUrl}/DeviceModels`,
      filters
    ); */
    return of([
      {
        id: "_unknown_",
        name: "UNKNOWN",
      },
      {
        id: "asus p1512cea_p1512cea",
        name: "asus p1512cea_p1512cea",
      },
    ]);
  }

  async get_filterTypes(
    currentViewName: string,
    userId: string = "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
    rolesIds: string[] = ["085dae0a-01e6-4cf7-88fe-08d87f1cd1ad"]
  ): Promise<Observable<GeneralData[]>> {
    /* return this.http.get<GeneralData[]>(
      `${this.generalDataApiUrl}/FilterTypes`
    ); */
    const filters = await this.filtersService.getViewFilters("").toPromise();
    const viewFiltersFilterTypes = filters
      ?.filter(
        (r: FilterInstance) =>
          r.name == "filterType" &&
          r.view == currentViewName &&
          (r.filterType == "default" ||
            r.filterType == "global" ||
            r.userId == userId ||
            rolesIds.includes(r.rolId))
      )!
      .map((v: any) => ({
        id: v.defaultValue,
        name: capitalize(v.defaultValue),
      })) as GeneralData[];
    return of(
      viewFiltersFilterTypes /* [      
      {
          "id": "global",
          "name": "Global"
      },
      {
        "id": "default",
        "name": "Default"
      },
      {
          "id": "rol",
          "name": "Rol"
      },
      {
          "id": "user",
          "name": "User"
      }
      
    ] */
    );
  }

  get_timeIntervals(): Observable<GeneralData[]> {
    /* return this.http.get<GeneralData[]>(
      `${this.generalDataApiUrl}/TimeIntervals`
    ); */
    return of([
      { id: "10", name: "10m" },
      { id: "30", name: "30m" },
      { id: "60", name: "1h" },
    ]);
  }

  get_timeIntervalsMinutes(): Observable<GeneralData[]> {
    /* return this.http.get<GeneralData[]>(
      `${this.generalDataApiUrl}/TimeIntervalsMinutes`
    ); */
    return of([
      {
        id: "-1",
        name: "WITHOUT_REFRESH",
      },
      {
        id: "5",
        name: "5s",
      },
      {
        id: "15",
        name: "15s",
      },
      {
        id: "30",
        name: "30s",
      },
      {
        id: "60",
        name: "1m",
      },
      {
        id: "300",
        name: "5m",
      },
    ]);
  }

  get_timeIntervalsDays(): Observable<GeneralData[]> {
    /* return this.http.get<GeneralData[]>(
      `${this.generalDataApiUrl}/TimeIntervalsDays`
    ); */
    return of([
      {
        id: "1440",
        name: "1D",
      },
      {
        id: "2880",
        name: "2D",
      },
      {
        id: "7200",
        name: "5D",
      },
      {
        id: "10080",
        name: "7D",
      },
      {
        id: "21600",
        name: "15D",
      },
      {
        id: "43200",
        name: "30D",
      },
    ]);
  }

  get_durationIntervalsHours(): Observable<GeneralData[]> {
    /* return this.http.get<GeneralData[]>(
      `${this.generalDataApiUrl}/DurationIntervalsHours`
    ); */
    return of([
      {
        id: "0",
        name: "MIN",
      },
      {
        id: "3600",
        name: "1H",
      },
    ]);
  }

  get_processes(filters: Filters): Observable<GeneralData[]> {
    /* return this.http.post<GeneralData[]>(
      `${this.generalDataApiUrl}/Processes`,
      filters
    ); */
    return of([
      {
        id: "121.0.6167.85_120.0.6099.225_chrome_updater.exe",
        name: "121.0.6167.85_120.0.6099.225_chrome_updater.exe",
      },
      {
        id: "abmsvchost.exe",
        name: "abmsvchost.exe",
      },
    ]);
  }

  get_departments(filters: Filters): Observable<IGeneralData[]> {
    //Department[]
    return this.getFilteredData<IGeneralData>("Department", filters);
    const results = [
      {
        name: "BU Development",
        departmentEmployees: null,
        departmentsWorkTypes: null,
        schedules: null,
        parentId: null,
        childDepartments: null,
        departmentTelemetryHosts: null,
        functionTelemetryHosts: null,
        id: "be2e6cb9-9312-47f8-815e-08d90f17eacf",
        extendedItem: null,
        tenantId: "7b108135-7283-4639-00a0-08d87f1cd182",
        isDeleted: false,
        createdBy: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        updatedBy: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        createdDate: "2021-05-04T16:16:04.7938675",
        updatedDate: "2022-05-31T06:29:08.1767542",
        timestamp: "AAAAAACV51c=",
        accessLevel: null,
      },
      {
        name: "BU Proceses & automation",
        departmentEmployees: null,
        departmentsWorkTypes: null,
        schedules: null,
        parentId: null,
        childDepartments: null,
        departmentTelemetryHosts: null,
        functionTelemetryHosts: null,
        id: "21587571-8edc-469b-815f-08d90f17eacf",
        extendedItem: null,
        tenantId: "7b108135-7283-4639-00a0-08d87f1cd182",
        isDeleted: false,
        createdBy: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        updatedBy: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        createdDate: "2021-05-04T16:16:28.4317859",
        updatedDate: "2022-05-30T16:32:51.3407928",
        timestamp: "AAAAAACVlfw=",
        accessLevel: null,
      },
    ];
    return of(results);
  }

  get_softwareProducts(filters: Filters): Observable<GeneralData[]> {
    /* return this.http.post<GeneralData[]>(
      `${this.generalDataApiUrl}/SoftwareProducts`,
      filters
    ); */
    return of([
      {
        id: "f22706df-d332-4714-7c3d-08dac0c96e58",
        name: "\\7-zip",
      },
      {
        id: "fe3cebd3-6c5f-4e74-8b0c-08dac1929cfd",
        name: "Abaqus",
      },
    ]);
  }

  get_singleApps(filters: Filters): Observable<GeneralData[]> {
    /* return this.http.post<GeneralData[]>(
      `${this.generalDataApiUrl}/SingleApps`,
      filters
    ); */
    return of([
      {
        id: "7zg.exe",
        name: "7-zip [7zg.exe]",
      },
      {
        id: "applicationframehost.exe",
        name: "microsoft® windows® operating system [applicationframehost.exe]",
      },
    ]);
  }

  get_employees(filters: Filters): Observable<IGeneralData[]> {
    //Employee[]
    return this.getFilteredData<IGeneralData>("Employees", filters);

    const results = [
      {
        name: "black10",
        identityUserId: "ec7f33c1-9108-4e51-83c2-08dadb5e9419",
        canViewAllEmployees: false,
        departmentEmployees: null,
        employeeFunctions: null,
        employeeTelemetryUsers: null,
        employeeTelemetryHosts: null,
        employeeRoles: null,
        employeeEmployeeNameTags: null,
        id: "1c76a346-e46d-4dcd-0dc2-08dadb5eb6e3",
        extendedItem: null,
        tenantId: "7b108135-7283-4639-00a0-08d87f1cd182",
        isDeleted: false,
        createdBy: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        updatedBy: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        createdDate: "2022-12-11T10:01:46.8109556",
        updatedDate: "2022-12-11T10:01:46.8109556",
        timestamp: "AAAAAAFd7kE=",
        accessLevel: null,
      },
      {
        name: "Eduado Vazquez",
        identityUserId: "e72d7de8-63aa-472e-31f3-08daea414117",
        canViewAllEmployees: false,
        departmentEmployees: null,
        employeeFunctions: null,
        employeeTelemetryUsers: null,
        employeeTelemetryHosts: null,
        employeeRoles: null,
        employeeEmployeeNameTags: null,
        id: "bb2e66a6-11be-4dc7-e4fc-08daea5d7839",
        extendedItem: null,
        tenantId: "7b108135-7283-4639-00a0-08d87f1cd182",
        isDeleted: false,
        createdBy: "00000000-0000-0000-0000-000000000000",
        updatedBy: "00000000-0000-0000-0000-000000000000",
        createdDate: "2022-12-30T12:00:40.1936611",
        updatedDate: "2022-12-30T12:00:40.1936611",
        timestamp: "AAAAAAF1wCk=",
        accessLevel: null,
      },
    ];
    return of(results);
  }

  get_employeeTags(filters: Filters): Observable<any[]> {
    //return this.http.post<any[]>(`${this.generalDataApiUrl}/EmployeeTags`,filters);
    return of([
      {
        id: "489980ad-2e25-4742-4512-08dac187b038",
        name: "analisis 2",
      },
      {
        id: "4f80240a-3697-47fa-8127-08db6cf4fc8f",
        name: "demo",
      },
    ]);
  }

  get_functions(filters: Filters): Observable</* Function */ any[]> {
    /* return this.http.post<Function[]>(
      `${this.generalDataApiUrl}/Functions`,
      filters
    ); */
    return of([
      {
        name: "Analista de datos",
        employeeFunctions: null,
        functionTelemetryHosts: null,
        id: "35246772-a505-49e7-bf3c-08d97dae1799",
        extendedItem: null,
        tenantId: "7b108135-7283-4639-00a0-08d87f1cd182",
        isDeleted: false,
        createdBy: "d6779bf5-8385-40f4-ec41-08d9693fa417",
        updatedBy: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        createdDate: "2021-09-22T09:48:12.3518295",
        updatedDate: "2023-06-14T12:51:41.8145015",
        timestamp: "AAAAAAK/Y64=",
        accessLevel: null,
      },
      {
        name: "Programador",
        employeeFunctions: null,
        functionTelemetryHosts: null,
        id: "4348a208-eb63-4978-de53-08da427217d0",
        extendedItem: null,
        tenantId: "7b108135-7283-4639-00a0-08d87f1cd182",
        isDeleted: false,
        createdBy: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        updatedBy: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        createdDate: "2022-05-30T19:25:01.9768328",
        updatedDate: "2022-05-30T19:25:01.9768328",
        timestamp: "AAAAAACVsvY=",
        accessLevel: null,
      },
      {
        name: "Arquitecto",
        employeeFunctions: null,
        functionTelemetryHosts: null,
        id: "55d3341a-1b5a-4f85-de54-08da427217d0",
        extendedItem: null,
        tenantId: "7b108135-7283-4639-00a0-08d87f1cd182",
        isDeleted: false,
        createdBy: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        updatedBy: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        createdDate: "2022-05-30T19:25:10.3781029",
        updatedDate: "2022-05-30T19:25:10.3781029",
        timestamp: "AAAAAACVsvc=",
        accessLevel: null,
      },
      {
        name: "Contable",
        employeeFunctions: null,
        functionTelemetryHosts: null,
        id: "662e275c-a91d-4089-de55-08da427217d0",
        extendedItem: null,
        tenantId: "7b108135-7283-4639-00a0-08d87f1cd182",
        isDeleted: false,
        createdBy: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        updatedBy: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        createdDate: "2022-05-30T19:25:15.5351907",
        updatedDate: "2022-05-30T19:25:15.5351907",
        timestamp: "AAAAAACVsvg=",
        accessLevel: null,
      },
      {
        name: "Recepción",
        employeeFunctions: null,
        functionTelemetryHosts: null,
        id: "ee4417dc-ab95-461b-de56-08da427217d0",
        extendedItem: null,
        tenantId: "7b108135-7283-4639-00a0-08d87f1cd182",
        isDeleted: false,
        createdBy: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        updatedBy: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        createdDate: "2022-05-30T19:25:28.7873104",
        updatedDate: "2022-05-30T19:25:28.7873104",
        timestamp: "AAAAAACVsvk=",
        accessLevel: null,
      },
      {
        name: "Gerente",
        employeeFunctions: null,
        functionTelemetryHosts: null,
        id: "6c632e14-5733-410d-de57-08da427217d0",
        extendedItem: null,
        tenantId: "7b108135-7283-4639-00a0-08d87f1cd182",
        isDeleted: false,
        createdBy: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        updatedBy: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        createdDate: "2022-05-30T19:25:49.6079047",
        updatedDate: "2022-05-30T19:25:49.6079047",
        timestamp: "AAAAAACVsvo=",
        accessLevel: null,
      },
      {
        name: "Otras",
        employeeFunctions: null,
        functionTelemetryHosts: null,
        id: "eacf0225-53bc-4469-de58-08da427217d0",
        extendedItem: null,
        tenantId: "7b108135-7283-4639-00a0-08d87f1cd182",
        isDeleted: false,
        createdBy: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        updatedBy: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        createdDate: "2022-05-30T19:25:55.8216173",
        updatedDate: "2022-11-08T12:53:18.0937048",
        timestamp: "AAAAAAEhAuY=",
        accessLevel: null,
      },
    ]);
  }

  get_roles(filters: Filters): Observable<GeneralData[]> {
    /* return this.http.post<GeneralData[]>(
      `${this.generalDataApiUrl}/Roles`,
      filters
    ); */
    return of([
      {
        id: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        name: "Admin",
        description: "Default administrator",
      },
    ]);
  }

  /* getInfoView(): Observable<InfoView> {
    return this.http.get<InfoView>(`${this.generalDataApiUrl}/InfoView`);
  } */

  get_measureTypes(): Observable<GeneralData[]> {
    /* return this.http.get<GeneralData[]>(
      `${this.generalDataApiUrl}/MeasureTypes`
    ); */
    const results = [
      {
        id: "average",
        name: "AVERAGE",
      },
      {
        id: "median",
        name: "MEDIAN",
      },
      {
        id: "sum",
        name: "SUM",
      },
    ];
    return of(results);
  }

  get_groupTypes(): Observable<GeneralData[]> {
    //return this.http.get<GeneralData[]>(`${this.generalDataApiUrl}/GroupTypes`);
    return of([
      {
        id: "department",
        name: "DEPARTMENT",
      },
      {
        id: "function",
        name: "FUNCTION",
      },
    ]);
  }

  /* changeViewMode(filters: Filters): Observable<InfoView> {
    return this.http.post<InfoView>(
      `${this.generalDataApiUrl}/ChangeViewMode`,
      filters
    );
  } */

  get_categories(): Observable<any[]> {
    //Category[]
    return this.http.get<any[]>(`${this.apiBaseUrl}/Categories`); //Category[]
  }

  get_appTypes(): Observable<GeneralData[]> {
    const appTypes = [
      { id: "url", name: "Url" },
      { id: "app", name: "App" },
      { id: "mapp", name: "MApp" },
    ];
    return of(appTypes);
  }

  get_taskTypes(filters?: Filters): Observable<GeneralData[]> {
    const taskTypes = [
      { name: "CALENDAR_EVENT", id: "0" },
      { name: "WORK_OFFLINE", id: "1" },
      { name: "ACTIVITY_PRODUCTIVE", id: "2" },
    ];
    return of(taskTypes);
  }

  get_showInfoTypes(filters?: Filters): Observable<GeneralData[]> {
    const showInfoTypes = [
      { name: "INFO_DISPLAYED.EXISTENCE", id: "0" },
      { name: "INFO_DISPLAYED.N_OF_SIGNALS", id: "1" },
      { name: "INFO_DISPLAYED.DURATION", id: "2" },
    ];
    return of(showInfoTypes);
  }

  get_tasksStatus(): Observable<GeneralData[]> {
    const taskTypes = [
      { name: "REJECTED", id: "0" },
      { name: "APPROVED", id: "1" },
      { name: "PENDING", id: "2" },
    ];
    return of(taskTypes);
  }

  get_yesOrNo(): Observable<GeneralData[]> {
    const options = [
      { name: "ACTION.YES", id: "1" },
      { name: "ACTION.NO", id: "0" },
    ];
    return of(options);
  }

  get_yesOrNoOrAll(): Observable</* GeneralDataBoolean */ GeneralData[]> {
    const options = [
      { name: "ACTION.YES", id: "true" },
      { name: "ACTION.NO", id: "false" },
      { name: "ACTION.ALL", id: "null" },
    ];
    return of(options);
  }

  get_trueOrFalse(): Observable</* GeneralDataBoolean */ GeneralData[]> {
    const options = [
      { name: "ACTION.YES", id: "true" },
      { name: "ACTION.NO", id: "false" },
    ];
    return of(options);
  }

  get_dataTypes(): Observable<GeneralData[]> {
    const data = [
      {
        name: "VIEWS.COMMONS.TABLES.COLUMNS.HOST.HEADER",
        id: "dataTypeDevice",
      },
      { name: "VIEWS.COMMONS.TABLES.COLUMNS.USER.HEADER", id: "dataTypeUser" },
    ];
    return of(data);
  }

  get_screenTypes(): Observable<GeneralData[]> {
    const screens = [
      { name: "VIEWS.LAST_SIGNS.TITLE", id: "screenTypeLastSignals" },
      { name: "VIEWS.LAST_SIGNS.ANOMALIES", id: "screenTypeAnomalies" },
    ];
    return of(screens);
  }

  get_numItems(): Observable<GeneralData[]> {
    const numItems = [
      { name: "200", id: "200" },
      { name: "100", id: "100" },
      { name: "50", id: "50" },
      { name: "25", id: "25" },
      { name: "10", id: "10" },
      { name: "5", id: "5" },
      { name: "0", id: "0" },
    ];
    return of(numItems);
  }

  get_hostsInformations(): Observable<GeneralData[]> {
    const optionsHost = [
      { name: "VIEWS.HOSTS_INFORMATION.CPU", id: "hostsInformationCpu" },
      { name: "VIEWS.HOSTS_INFORMATION.GPU", id: "hostsInformationGpu" },
      { name: "VIEWS.HOSTS_INFORMATION.RAM", id: "hostsInformationRam" },
      { name: "VIEWS.HOSTS_INFORMATION.SO", id: "hostsInformationSo" },
    ];
    return of(optionsHost);
  }

  get_tierTops(): Observable<GeneralData[]> {
    const tops = [
      { name: "ALL_DATA", id: "-1" },
      { name: "3", id: "3" },
      { name: "5", id: "5" },
      { name: "10", id: "10" },
      { name: "15", id: "15" },
    ];
    return of(tops);
  }

  get_softwareDataTypes(): Observable<GeneralData[]> {
    const data = [
      { name: "VIEWS.TOP_APPLICATIONS.EXECUTIONS", id: "usages" },
      { name: "VIEWS.COMMONS.TABLES.COLUMNS.DURATION.HEADER", id: "duration" },
      { name: "VIEWS.TOP_APPLICATIONS.FOCUS_EXECUTIONS", id: "focusUsages" },
      { name: "VIEWS.TOP_APPLICATIONS.FOCUS_DURATION", id: "focusDuration" },
      { name: "VIEWS.TOP_APPLICATIONS.AVERAGE_MEMORY", id: "ramUsageAvg" },
      { name: "VIEWS.TOP_APPLICATIONS.AVERAGE_CPU_USAGE", id: "cpuUsageAvg" },
    ];
    return of(data);
  }

  get_deviceOrUser(): Observable<GeneralData[]> {
    const data = [
      { name: "VIEWS.COMMONS.TABLES.COLUMNS.HOST.HEADER", id: "device" },
      { name: "VIEWS.COMMONS.TABLES.COLUMNS.EMPLOYEE.HEADER", id: "user" },
    ];
    return of(data);
  }

  get_windowsEventsIds(filters: Filters): Observable<GeneralData[]> {
    /* return this.http.post<GeneralData[]>(
      `${this.generalDataApiUrl}/GetWindowsEventsIds`,
      filters
    ); */
    return of([
      {
        id: "0",
        name: "0",
      },
      {
        id: "1",
        name: "1",
      },
    ]);
  }

  get_windowsEventsChannels(filters: Filters): Observable<GeneralData[]> {
    /* return this.http.post<GeneralData[]>(
      `${this.generalDataApiUrl}/GetWindowsEventsChannels`,
      filters
    ); */
    return of([
      {
        id: "Application",
        name: "Application",
      },
      {
        id: "Security",
        name: "Security",
      },
      {
        id: "System",
        name: "System",
      },
    ]);
  }

  get_windowsEventsLevels(filters: Filters): Observable<GeneralData[]> {
    /* return this.http.post<GeneralData[]>(
      `${this.generalDataApiUrl}/GetWindowsEventsLevels`,
      filters
    ); */
    return of([
      { id: "2", name: "VIEWS.COMMONS.TABLES.COLUMNS.WINDOWS_EVENTS.ERROR" },
    ]);
  }

  get_hostNameTags(filters: Filters): Observable<GeneralData[]> {
    /* return this.http.post<GeneralData[]>(
      `${this.generalDataApiUrl}/GetHostNameTags`,
      filters
    ); */
    return of([
      {
        id: "f287b101-5502-4dca-c543-08db8c2324b2",
        name: "italia",
      },
      {
        id: "56e30f94-c9cf-45bf-c545-08db8c2324b2",
        name: "europa",
      },
    ]);
  }

  ///
  setDefaultFiltersByRole(
    filters: any /* Filters */,
    view: string,
    rolid: any
  ): Observable<ConfigDefaultFilters[]> {
    return this.http.post<ConfigDefaultFilters[]>(
      `${this.apiBaseUrl}/FilterConfigDefault/SetDefaultFilterByRole/${view}/${rolid}`,
      filters
    );
  }

  setDefaultFiltersByUser(
    filters: Filters,
    view: string,
    userid: any
  ): Observable<ConfigDefaultFilters[]> {
    return this.http.post<ConfigDefaultFilters[]>(
      `${this.apiBaseUrl}/FilterConfigDefault/SetDefaultFilterByUser/${view}/${userid}`,
      filters
    );
  }

  getSavedFilters(
    rolid: any,
    userid: any
  ): Observable</* ConfigDefaultFilters */ any[]> {
    /* return this.http.get<ConfigDefaultFilters[]>(
      `${this.baseUrl}/FilterConfigDefault/GetDefaultFilters/${rolid}/${userid}`
    ); */
    const results = [
      {
        view: "t",
        value: "2880",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: null,
        filterId: "00000000-0000-0000-0000-000000000000",
        filter: null,
        nameFilter: "TimeIntervalDays",
        filters: null,
        id: "00000000-0000-0000-0000-000000000000",
        extendedItem: null,
        tenantId: "00000000-0000-0000-0000-000000000000",
        isDeleted: false,
        createdBy: "00000000-0000-0000-0000-000000000000",
        updatedBy: "00000000-0000-0000-0000-000000000000",
        createdDate: "0001-01-01T00:00:00",
        updatedDate: "0001-01-01T00:00:00",
        timestamp: null,
        accessLevel: null,
      },
      {
        view: "t",
        value: "0.9.5",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: null,
        filterId: "00000000-0000-0000-0000-000000000000",
        filter: null,
        nameFilter: "AppVersions",
        filters: null,
        id: "00000000-0000-0000-0000-000000000000",
        extendedItem: null,
        tenantId: "00000000-0000-0000-0000-000000000000",
        isDeleted: false,
        createdBy: "00000000-0000-0000-0000-000000000000",
        updatedBy: "00000000-0000-0000-0000-000000000000",
        createdDate: "0001-01-01T00:00:00",
        updatedDate: "0001-01-01T00:00:00",
        timestamp: null,
        accessLevel: null,
      },
      {
        view: "t",
        value: "0.5.8",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: null,
        filterId: "00000000-0000-0000-0000-000000000000",
        filter: null,
        nameFilter: "AppVersions",
        filters: null,
        id: "00000000-0000-0000-0000-000000000000",
        extendedItem: null,
        tenantId: "00000000-0000-0000-0000-000000000000",
        isDeleted: false,
        createdBy: "00000000-0000-0000-0000-000000000000",
        updatedBy: "00000000-0000-0000-0000-000000000000",
        createdDate: "0001-01-01T00:00:00",
        updatedDate: "0001-01-01T00:00:00",
        timestamp: null,
        accessLevel: null,
      },
      {
        view: "GlobalFiltersComponent",
        value: "user",
        rolId: null,
        userId: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        filterId: "00000000-0000-0000-0000-000000000000",
        filter: null,
        nameFilter: "filterType",
        filters: null,
        id: "00000000-0000-0000-0000-000000000000",
        extendedItem: null,
        tenantId: "00000000-0000-0000-0000-000000000000",
        isDeleted: false,
        createdBy: "00000000-0000-0000-0000-000000000000",
        updatedBy: "00000000-0000-0000-0000-000000000000",
        createdDate: "0001-01-01T00:00:00",
        updatedDate: "0001-01-01T00:00:00",
        timestamp: null,
        accessLevel: null,
      },
      {
        view: "GlobalFiltersComponent",
        value: "30",
        rolId: null,
        userId: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        filterId: "00000000-0000-0000-0000-000000000000",
        filter: null,
        nameFilter: "TimeInterval",
        filters: null,
        id: "00000000-0000-0000-0000-000000000000",
        extendedItem: null,
        tenantId: "00000000-0000-0000-0000-000000000000",
        isDeleted: false,
        createdBy: "00000000-0000-0000-0000-000000000000",
        updatedBy: "00000000-0000-0000-0000-000000000000",
        createdDate: "0001-01-01T00:00:00",
        updatedDate: "0001-01-01T00:00:00",
        timestamp: null,
        accessLevel: null,
      },
      {
        view: "GlobalFiltersComponent",
        value: "2880",
        rolId: null,
        userId: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        filterId: "00000000-0000-0000-0000-000000000000",
        filter: null,
        nameFilter: "TimeIntervalDays",
        filters: null,
        id: "00000000-0000-0000-0000-000000000000",
        extendedItem: null,
        tenantId: "00000000-0000-0000-0000-000000000000",
        isDeleted: false,
        createdBy: "00000000-0000-0000-0000-000000000000",
        updatedBy: "00000000-0000-0000-0000-000000000000",
        createdDate: "0001-01-01T00:00:00",
        updatedDate: "0001-01-01T00:00:00",
        timestamp: null,
        accessLevel: null,
      },
      {
        view: "GlobalFiltersComponent",
        value: "-1",
        rolId: null,
        userId: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        filterId: "00000000-0000-0000-0000-000000000000",
        filter: null,
        nameFilter: "timeIntervalMinutes",
        filters: null,
        id: "00000000-0000-0000-0000-000000000000",
        extendedItem: null,
        tenantId: "00000000-0000-0000-0000-000000000000",
        isDeleted: false,
        createdBy: "00000000-0000-0000-0000-000000000000",
        updatedBy: "00000000-0000-0000-0000-000000000000",
        createdDate: "0001-01-01T00:00:00",
        updatedDate: "0001-01-01T00:00:00",
        timestamp: null,
        accessLevel: null,
      },
      {
        view: "GlobalFiltersComponent",
        value: "ALL",
        rolId: null,
        userId: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        filterId: "00000000-0000-0000-0000-000000000000",
        filter: null,
        nameFilter: "productivityType",
        filters: null,
        id: "00000000-0000-0000-0000-000000000000",
        extendedItem: null,
        tenantId: "00000000-0000-0000-0000-000000000000",
        isDeleted: false,
        createdBy: "00000000-0000-0000-0000-000000000000",
        updatedBy: "00000000-0000-0000-0000-000000000000",
        createdDate: "0001-01-01T00:00:00",
        updatedDate: "0001-01-01T00:00:00",
        timestamp: null,
        accessLevel: null,
      },
      {
        view: "GlobalFiltersComponent",
        value: "sum",
        rolId: null,
        userId: "f6a555e5-f08f-42ad-b566-08d87f1cd212",
        filterId: "00000000-0000-0000-0000-000000000000",
        filter: null,
        nameFilter: "measureType",
        filters: null,
        id: "00000000-0000-0000-0000-000000000000",
        extendedItem: null,
        tenantId: "00000000-0000-0000-0000-000000000000",
        isDeleted: false,
        createdBy: "00000000-0000-0000-0000-000000000000",
        updatedBy: "00000000-0000-0000-0000-000000000000",
        createdDate: "0001-01-01T00:00:00",
        updatedDate: "0001-01-01T00:00:00",
        timestamp: null,
        accessLevel: null,
      },
    ];
    return of(results);
  }

  deleteDefaultFilter(view: string): Observable<ConfigDefaultFilters[]> {
    return this.http.delete<ConfigDefaultFilters[]>(
      `${this.apiBaseUrl}/FilterConfigDefault/DeleteDefaultFilters/${view}`
    );
  }
}
