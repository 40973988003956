import { inject, Injectable } from "@angular/core";
import { GoldenLayoutComponentService } from "./golden-layout-component.service";
import { GoldenLayout, LayoutConfig, LayoutManager } from "golden-layout";
import { GLTabState, GoldenLayoutHostComponent } from "./golden-layout-host.component";
import { GL_INITIAL_LAYOUT, GL_INITIAL_LAYOUT_NO_TABS, GL_LOCALSTORAGE_STATE } from "./golden-layout-consts";
import { logLog } from "@overa/shared";
import { UrlPatternMatcher } from "./golden-layout-utils";
import { BehaviorSubject, Observable, Subject } from "rxjs";



@Injectable({
    providedIn: "root",
})
export class GoldenLayoutManagerService {


    private _goldenLayout?: GoldenLayout;
    private _goldenLayoutHostComponent?: GoldenLayoutHostComponent;
    private _allwaysOpenNewTabOnGL: boolean = false;

    private readonly _goldenLayoutIsInitializedSubject = new BehaviorSubject<boolean>(false);

    _goldenLayoutComponentService = inject(GoldenLayoutComponentService);

    constructor() {
        console.log("GL - Constructor");
    }

    onInitialized() {
        this._goldenLayoutIsInitializedSubject.next(true);
    }

    /**
     * Returns an observable that emits a boolean value indicating whether the Golden Layout has been initialized.
     *
     * @returns {Observable<boolean>} An observable that emits `true` when the Golden Layout is initialized, and `false` otherwise.
     */
    getInitializedEvent(): Observable<boolean> {
        return this._goldenLayoutIsInitializedSubject.asObservable();
    }


    get goldenLayout(): GoldenLayout | undefined {
        return this._goldenLayout;
    }

    /**
     * Sets the value indicating whether a new tab should always be opened in Golden Layout.
     * 
     * @param value - A boolean value where `true` means always open a new tab and `false` means do not always open a new tab.
     */
    set allwaysOpenNewTabOnGL(value: boolean) {
        this._allwaysOpenNewTabOnGL = value;
    }


    /**
     * Initializes the GoldenLayout manager with the provided host component.
     * 
     * This method sets up the GoldenLayout host component, initializes it, and configures its settings.
     * It also loads the saved layout state after initialization.
     * 
     * @param goldenLayoutHost - The GoldenLayoutHostComponent to be initialized.
     */
    initialize(goldenLayoutHost: GoldenLayoutHostComponent, disableTabs: boolean = false) {

        //setTimeout(() => {
        console.log("GL - INITIALIZING");
        this._goldenLayoutHostComponent = goldenLayoutHost;
        this._goldenLayoutHostComponent.initialise();
        this._goldenLayoutHostComponent.setVirtualActive(false);
        this._goldenLayoutHostComponent.setAllwaysOpenNewTab(this._allwaysOpenNewTabOnGL);
        //this._goldenLayoutHostComponent.setViewContainerRefActive(true);
        this._goldenLayout = this._goldenLayoutHostComponent.goldenLayout;
        console.log("GL - INITIALIZED");
        this.loadSavedLayoutState(disableTabs);
        this.onInitialized();
        //}, 1);

    }

    /**
     * Loads the saved layout state from the local storage and applies it to the Golden Layout instance.
     * If no saved state is found, or if an error occurs during the restoration process, the initial layout is loaded.
     *
     * @remarks
     * This method attempts to retrieve the layout state from the local storage using the key `GL_LOCALSTORAGE_STATE`.
     * If the state is found, it is parsed and used to restore the layout. If parsing fails or no state is found,
     * the initial layout defined by `GL_INITIAL_LAYOUT` is loaded instead.
     *
     * @throws {Error} If there is an issue with parsing the saved state from local storage.
     */
    loadSavedLayoutState(disableTabs: boolean = false) {
        if (this._goldenLayout && this._goldenLayoutHostComponent) {
            if (!disableTabs) {

                const savedState = localStorage.getItem(GL_LOCALSTORAGE_STATE);
                if (savedState) {
                    try {
                        const state = JSON.parse(savedState);
                        const layoutToRestore = LayoutConfig.fromResolved(state);
                        this._goldenLayout.loadLayout(layoutToRestore);
                    } catch (e) {
                        console.error("GL - Error restoring layout state:", e);
                        this._goldenLayout.loadLayout(GL_INITIAL_LAYOUT);
                    }
                } else {
                    this._goldenLayout.loadLayout(GL_INITIAL_LAYOUT);
                }

            } else {
                this._goldenLayout.loadLayout(GL_INITIAL_LAYOUT_NO_TABS);
            }


        } else {
            throw new Error(`GL - No golden layout ready. Golden Layout: ${this._goldenLayout}, Host Component: ${this._goldenLayoutHostComponent}.`);
        }
    }


    /**
     * Loads a saved layout configuration into the golden layout instance.
     *
     * @param value - A JSON string representing the saved layout configuration.
     * @throws {Error} Throws an error if the golden layout instance is not initialized.
     */
    loadSavedLayout(value: string) {
        if (this._goldenLayout && this._goldenLayoutHostComponent) {
            const currentLayoutValueJson = JSON.parse(value);
            const layoutConfig = LayoutConfig.fromResolved(currentLayoutValueJson);
            this._goldenLayout.loadLayout(layoutConfig);
        } else {
            throw new Error(`GL - No golden layout ready. Golden Layout: ${this._goldenLayout}, Host Component: ${this._goldenLayoutHostComponent}.`);
        }
    }

    /**
     * Finds a registered component by its URL.
     *
     * This method searches through the registered component types to find a match
     * for the provided URL. If a match is found, it returns an object containing
     * the component name and any parameters extracted from the URL. If no match
     * is found, it returns `undefined`.
     *
     * @param url - The URL to search for a matching component.
     * @returns An object containing the component name and parameters if a match is found, or `undefined` if no match is found.
     */
    findComponentByUrl(url: string): { componentName: string, params: { [key: string]: string } } | undefined {
        logLog(`GL - Get component ${url}`);
        const urlNormalize = UrlPatternMatcher.trimSlash(url);

        const registeredGoldenComponents =
            this._goldenLayoutComponentService.getRegisteredComponentTypeNames();

        const componentRegistered = registeredGoldenComponents.find(pattern => {
            const p = UrlPatternMatcher.trimSlash(pattern);
            return UrlPatternMatcher.isMatch(p, urlNormalize);
        });

        if (componentRegistered) {
            return { componentName: componentRegistered, params: UrlPatternMatcher.matchUrlPattern(componentRegistered, urlNormalize) };
        }
        return undefined;
    }


    /**
     * Opens a URL in the Golden Layout.
     * 
     * @param url - The URL to be opened.
     * @param name - The name of the component to be opened. Can be undefined.
     * @param params - An object containing key-value pairs of parameters to be passed to the component.
     * 
     * @throws {Error} Throws an error if the Golden Layout instance or host component is not available.
     */
    openUrl(
        url: string,
        name: string | undefined,
        params: { [key: string]: string }
    ) {
        if (this._goldenLayout && this._goldenLayoutHostComponent) {

            const currentActiveItem = this._goldenLayoutHostComponent.getActiveItem();

            const currentState = (currentActiveItem?.container.getState() as GLTabState) || {};

            if (
                currentActiveItem &&
                !currentState.locked
            ) {
                this._goldenLayout.newComponentAtLocation(
                    url,
                    {
                        ...params,
                    },
                    name,
                    [
                        {
                            typeId: LayoutManager.LocationSelector.TypeId.FocusedItem,
                            index: 0,
                        },
                    ]
                );
                this._goldenLayout.focusedComponentItem?.parent?.removeChild(currentActiveItem);
            } else {
                this._goldenLayout.addComponent(
                    url,
                    {
                        ...params,
                    },
                    name);
            }
        }
        else {
            throw new Error(`GL - No golden layout ready. Golden Layout: ${this._goldenLayout}, Host Component: ${this._goldenLayoutHostComponent}. URL: ${url}`);
        }
    }

}