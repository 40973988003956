import {
  ApplicationRef,
  Component,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import {
  ComponentContainer,
  ComponentItem,
  ContentItem,
  GoldenLayout,
  LogicalZIndex,
  ResolvedComponentItemConfig,
  Stack,
  Tab,
} from "golden-layout";
import { GoldenLayoutComponentService } from "./golden-layout-component.service";
import { BaseComponentDirective } from "@overa/shared";
import { GL_LOCALSTORAGE_STATE } from "./golden-layout-consts";

export const GOLDEN_LAYOUT_TAG_NAME = "golden-layout-host";

export interface GLTabState {
  locked?: boolean;
  focused?: boolean;
}

@Component({
  selector: GOLDEN_LAYOUT_TAG_NAME,
  template: "<ng-template #componentViewContainer></ng-template>",
  styles: [
    `
      :host {
        display: block;
        height: 100%;
        width: 100%;
        padding: 3;
        position: relative;
      }
    `,
  ],
})
/**
 * The `GoldenLayoutHostComponent` is responsible for managing the Golden Layout instance within the Angular application.
 * It handles the initialization, configuration, and lifecycle of the Golden Layout, as well as the binding and unbinding of components.
 * 
 * @implements {OnDestroy}
 * 
 * @class
 * @classdesc This component provides methods to initialize the Golden Layout, manage its state, and handle component events.
 * 
 * @property {GoldenLayout} _goldenLayout - The Golden Layout instance.
 * @property {HTMLElement} _goldenLayoutElement - The HTML element that hosts the Golden Layout.
 * @property {boolean} _virtualActive - Indicates whether the virtual active state is enabled.
 * @property {boolean} _viewContainerRefActive - Indicates whether the view container reference active state is enabled.
 * @property {Map<ComponentContainer, ComponentRef<BaseComponentDirective>>} _componentRefMap - A map of component references.
 * @property {DOMRect} _goldenLayoutBoundingClientRect - The bounding client rectangle of the Golden Layout element.
 * @property {Function} _goldenLayoutBindComponentEventListener - The event listener for binding components.
 * @property {Function} _goldenLayoutUnbindComponentEventListener - The event listener for unbinding components.
 * @property {ViewContainerRef} _componentViewContainerRef - The view container reference for components.
 * @property {boolean} allwaysOpenNewTabOnGL - Indicates whether to always open a new tab in Golden Layout.
 * 
 * @constructor
 * @param {ApplicationRef} _appRef - The Angular application reference.
 * @param {ElementRef<HTMLElement>} _elRef - The element reference for the Golden Layout host element.
 * @param {GoldenLayoutComponentService} goldenLayoutComponentService - The service for managing Golden Layout components.
 * @param {Renderer2} renderer - The Angular renderer for DOM manipulation.
 * 
 * @method load - Loads the specified components into the Golden Layout.
 * @param {any[]} components - The components to load.
 * @returns {Promise<void>}
 * 
 * @method ngOnDestroy - Cleans up the Golden Layout instance and its events.
 * 
 * @method initialise - Initializes the Golden Layout instance with the specified element and event listeners.
 * 
 * @method setVirtualActive - Sets the virtual active state of the component.
 * @param {boolean} value - A boolean indicating whether the virtual active state should be enabled or disabled.
 * 
 * @method setViewContainerRefActive - Sets the view container reference active state.
 * @param {boolean} value - A boolean indicating whether the view container reference active state should be enabled or disabled.
 * 
 * @method setSize - Sets the size of the Golden Layout.
 * @param {number} width - The width of the Golden Layout.
 * @param {number} height - The height of the Golden Layout.
 * 
 * @method getComponentRef - Retrieves the component reference for the specified container.
 * @param {ComponentContainer} container - The container to retrieve the component reference for.
 * @returns {ComponentRef<BaseComponentDirective> | undefined}
 * 
 * @method setAllwaysOpenNewTab - Sets the flag to always open a new tab in Golden Layout.
 * @param {boolean} value - A boolean value indicating whether to always open a new tab.
 * 
 * @method getActiveItem - Retrieves the currently active (focused) component item within the Golden Layout.
 * @returns {ComponentItem | undefined}
 * 
 * @method getComponentItems - Retrieves all component items within the Golden Layout.
 * @returns {ComponentItem[]}
 * 
 * @method onTabCreated - Event handler for when a new tab is created.
 * @param {Tab} newTab - The newly created tab.
 * 
 * @method onFocus - Event handler for when a component is focused.
 * @param {any} event - The focus event.
 * 
 * @method onActiveContentItemChanged - Event handler for when the active content item changes.
 * @param {ComponentItem} activeContentItem - The new active content item.
 * 
 * @method onItemDropped - Event handler for when an item is dropped.
 * @param {ComponentItem} item - The dropped item.
 * 
 * @method onStateChanged - Event handler for when the state of the Golden Layout changes.
 * 
 * @private
 * @method handleBindComponentEvent - Handles the binding of a component to a container.
 * @param {ComponentContainer} container - The container to bind the component to.
 * @param {ResolvedComponentItemConfig} itemConfig - The configuration of the component item.
 * @returns {ComponentContainer.BindableComponent}
 * 
 * @private
 * @method handleUnbindComponentEvent - Handles the unbinding of a component from a container.
 * @param {ComponentContainer} container - The container to unbind the component from.
 * 
 * @private
 * @method handleBeforeVirtualRectingEvent - Handles the event before virtual recting.
 * @param {number} _count - The count of virtual recting events.
 * 
 * @private
 * @method handleContainerVirtualRectingRequiredEvent - Handles the virtual recting required event for a container.
 * @param {ComponentContainer} container - The container requiring virtual recting.
 * @param {number} width - The width of the container.
 * @param {number} height - The height of the container.
 * 
 * @private
 * @method handleContainerVisibilityChangeRequiredEvent - Handles the visibility change required event for a container.
 * @param {ComponentContainer} container - The container requiring visibility change.
 * @param {boolean} visible - A boolean indicating whether the container should be visible.
 * 
 * @private
 * @method handleContainerVirtualZIndexChangeRequiredEvent - Handles the virtual z-index change required event for a container.
 * @param {ComponentContainer} container - The container requiring z-index change.
 * @param {LogicalZIndex} logicalZIndex - The logical z-index.
 * @param {string} defaultZIndex - The default z-index.
 * 
 * @private
 * @method blockTab - Toggles the lock state of a tab in the Golden Layout component.
 * @param {ComponentItem} content - The ComponentItem containing the tab to be blocked or unblocked.
 * @param {boolean} reset - A boolean indicating whether to reset the lock state.
 * 
 * @private
 * @method focusTab - Focuses a given tab and updates its visual state.
 * @param {Tab} tab - The tab to be focused.
 * @param {boolean} [isInit=true] - A boolean indicating if this is the initial focus.
 * 
 * @private
 * @method findStacksAndComponentsRecursively - Recursively finds stacks and components within the Golden Layout.
 * @param {ContentItem | undefined} item - The content item to start the search from.
 * @param {Stack[]} stacks - The array to store found stacks.
 * @param {ContentItem[]} components - The array to store found components.
 * 
 * @private
 * @method contentItemIsStack - Checks if a content item is a stack.
 * @param {ContentItem} item - The content item to check.
 * @returns {boolean}
 * 
 * @private
 * @method setLayoutEvents - Sets the event listeners for the Golden Layout.
 * 
 * @private
 * @method unsetLayoutEvents - Unsets the event listeners for the Golden Layout.
 */
export class GoldenLayoutHostComponent implements OnDestroy {

  private _goldenLayout!: GoldenLayout;

  private readonly _goldenLayoutElement: HTMLElement;

  private _virtualActive = true;

  private _viewContainerRefActive = false;

  private readonly _componentRefMap = new Map<
    ComponentContainer,
    ComponentRef<BaseComponentDirective>
  >();

  private _goldenLayoutBoundingClientRect: DOMRect = new DOMRect();

  private readonly _goldenLayoutBindComponentEventListener = (
    container: ComponentContainer,
    itemConfig: ResolvedComponentItemConfig,
  ) => this.handleBindComponentEvent(container, itemConfig)
  private readonly _goldenLayoutUnbindComponentEventListener = (
    container: ComponentContainer,
  ) => this.handleUnbindComponentEvent(container)

  @ViewChild('componentViewContainer', { read: ViewContainerRef, static: true })
  private readonly _componentViewContainerRef!: ViewContainerRef;

  private allwaysOpenNewTabOnGL: boolean = false;

  get goldenLayout() {
    return this._goldenLayout;
  }

  get virtualActive() {
    return this._virtualActive
  }
  get viewContainerRefActive() {
    return this._viewContainerRefActive
  }
  get isGoldenLayoutSubWindow() {
    return this._goldenLayout.isSubWindow
  }

  constructor(
    private readonly _appRef: ApplicationRef,
    private readonly _elRef: ElementRef<HTMLElement>,
    private readonly goldenLayoutComponentService: GoldenLayoutComponentService,
    private readonly renderer: Renderer2
  ) {
    this._goldenLayoutElement = this._elRef.nativeElement
  }

  /**
   * Asynchronously loads and registers an array of components with the Golden Layout service.
   *
   * @param components - An array of component objects to be registered. Each object should have a `typeName` and `component` property.
   * @returns A promise that resolves when all components have been registered.
   */
  async load(components: any[]): Promise<void> {
    components.forEach((component) => {
      this.goldenLayoutComponentService.registerComponentType(component.typeName, component.component);
    });
  }

  ngOnDestroy() {
    this.unsetLayoutEvents();
    this._goldenLayout.destroy();
  }

  /**
   * Initializes the GoldenLayout instance with the specified element and event listeners.
   * Sets the layout to resize automatically with the container and binds the beforeVirtualRectingEvent.
   * If the layout is a sub-window, it checks and adds the default pop-in button.
   * Finally, it sets up the layout events.
   *
   * @private
   */
  initialise() {
    this._goldenLayout = new GoldenLayout(
      this._goldenLayoutElement,
      this._goldenLayoutBindComponentEventListener,
      this._goldenLayoutUnbindComponentEventListener,
    )
    this._goldenLayout.resizeWithContainerAutomatically = true
    this._goldenLayout.beforeVirtualRectingEvent = (count) =>
      this.handleBeforeVirtualRectingEvent(count)

    if (this._goldenLayout.isSubWindow) {
      this._goldenLayout.checkAddDefaultPopinButton()
    }

    this.setLayoutEvents();
  }


  /**
   * Sets the virtual active state of the component.
   * 
   * @param value - A boolean indicating whether the virtual active state should be enabled or disabled.
   * 
   * When the virtual active state is set to false, the view container reference active state is also set to false.
   * Additionally, the golden layout is cleared whenever this method is called.
   */
  setVirtualActive(value: boolean) {
    this._goldenLayout.clear()
    this._virtualActive = value
    if (!this._virtualActive) {
      this._viewContainerRefActive = false
    }
  }

  setViewContainerRefActive(value: boolean) {
    this._goldenLayout.clear()
    if (value && !this.virtualActive) {
      throw new Error('ViewContainerRef active only possible if VirtualActive')
    }
    this._viewContainerRefActive = value
  }

  setSize(width: number, height: number) {
    this._goldenLayout.setSize(width, height)
  }

  getComponentRef(container: ComponentContainer) {
    return this._componentRefMap.get(container)
  }

  private handleBindComponentEvent(
    container: ComponentContainer,
    itemConfig: ResolvedComponentItemConfig,
  ): ComponentContainer.BindableComponent {
    const componentType = itemConfig.componentType
    const componentRef = this.goldenLayoutComponentService.createComponent(
      componentType,
      container,
    )
    const component = componentRef.instance;

    this._componentRefMap.set(container, componentRef);

    if (this._virtualActive) {
      container.virtualRectingRequiredEvent = (container, width, height) =>
        this.handleContainerVirtualRectingRequiredEvent(
          container,
          width,
          height,
        )
      container.virtualVisibilityChangeRequiredEvent = (container, visible) =>
        this.handleContainerVisibilityChangeRequiredEvent(container, visible)
      container.virtualZIndexChangeRequiredEvent = (
        container,
        logicalZIndex,
        defaultZIndex,
      ) =>
        this.handleContainerVirtualZIndexChangeRequiredEvent(
          container,
          logicalZIndex,
          defaultZIndex,
        )

      if (this._viewContainerRefActive) {
        this._componentViewContainerRef.insert(componentRef.hostView)
      } else {
        this._appRef.attachView(componentRef.hostView)
        const componentRootElement = component.rootHtmlElement
        this._goldenLayoutElement.appendChild(componentRootElement)
      }
    } else {
      this._appRef.attachView(componentRef.hostView);
      const domElem = (componentRef.hostView as EmbeddedViewRef<unknown>)
        .rootNodes[0] as HTMLElement;
      container.element.appendChild(domElem);
    }

    return {
      component,
      virtual: this._virtualActive,
    }
  }

  private handleUnbindComponentEvent(container: ComponentContainer) {
    const componentRef = this._componentRefMap.get(container)
    if (componentRef === undefined) {
      throw new Error('Could not unbind component. Container not found')
    }
    this._componentRefMap.delete(container)

    const hostView = componentRef.hostView

    if (container.virtual) {
      if (this._viewContainerRefActive) {
        const viewRefIndex = this._componentViewContainerRef.indexOf(hostView)
        if (viewRefIndex < 0) {
          throw new Error('Could not unbind component. ViewRef not found')
        }
        this._componentViewContainerRef.remove(viewRefIndex)
      } else {
        const component = componentRef.instance
        const componentRootElement = component.rootHtmlElement
        if (componentRootElement instanceof Node) {
          this._goldenLayoutElement.removeChild(componentRootElement)
        }
        this._appRef.detachView(hostView)
      }
    } else {
      const component = componentRef.instance
      const componentRootElement = component.rootHtmlElement
      if (componentRootElement instanceof Node) {
        container.element.removeChild(componentRootElement)
      }
      this._appRef.detachView(hostView)
    }

    componentRef.destroy()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private handleBeforeVirtualRectingEvent(_count: number) {
    this._goldenLayoutBoundingClientRect =
      this._goldenLayoutElement.getBoundingClientRect()
  }

  private handleContainerVirtualRectingRequiredEvent(
    container: ComponentContainer,
    width: number,
    height: number,
  ) {
    const containerBoundingClientRect =
      container.element.getBoundingClientRect()
    const left =
      containerBoundingClientRect.left -
      this._goldenLayoutBoundingClientRect.left
    const top =
      containerBoundingClientRect.top - this._goldenLayoutBoundingClientRect.top

    const componentRef = this._componentRefMap.get(container)
    if (componentRef === undefined) {
      throw new Error(
        'handleContainerVirtualRectingRequiredEvent: ComponentRef not found',
      )
    }
    const component = componentRef.instance
    component.setPositionAndSize(left, top, width, height)
  }

  private handleContainerVisibilityChangeRequiredEvent(
    container: ComponentContainer,
    visible: boolean,
  ) {
    const componentRef = this._componentRefMap.get(container)
    if (componentRef === undefined) {
      throw new Error(
        'handleContainerVisibilityChangeRequiredEvent: ComponentRef not found',
      )
    }
    const component = componentRef.instance
    component.setVisibility(visible)
  }

  private handleContainerVirtualZIndexChangeRequiredEvent(
    container: ComponentContainer,
    logicalZIndex: LogicalZIndex,
    defaultZIndex: string,
  ) {
    const componentRef = this._componentRefMap.get(container)
    if (componentRef === undefined) {
      throw new Error(
        'handleContainerVirtualZIndexChangeRequiredEvent: ComponentRef not found',
      )
    }
    const component = componentRef.instance
    component.setZIndex(defaultZIndex)
  }


  /**
   * Sets the flag to always open a new tab in Golden Layout.
   *
   * @param value - A boolean value indicating whether to always open a new tab.
   */
  setAllwaysOpenNewTab(value: boolean) {
    this.allwaysOpenNewTabOnGL = value;
  }


  /**
   * Toggles the lock state of a tab in the Golden Layout component.
   * 
   * This method adds a clickable icon to the tab element if it doesn't already exist.
   * Clicking the icon toggles the lock state of the tab and updates the icon accordingly.
   * 
   * @param content - The ComponentItem containing the tab to be blocked or unblocked.
   * @param reset - A boolean indicating whether to reset the lock state.
   * 
   * @private
   */
  private blockTab(content: ComponentItem, reset: boolean): void {
    const tabElement = content.tab.element;
    const tabBlockerClass = "tabBlocker";
    const blockIconClassLocked = "tabBlockIcon pi pi-unlock";
    const blockIconClassUnlocked = "tabBlockIcon pi pi-lock-open";

    if (!tabElement.querySelector(`.${tabBlockerClass}`)) {
      const blockIconDiv = this.renderer.createElement("div");
      this.renderer.setAttribute(blockIconDiv, "class", tabBlockerClass);

      const blockIcon = this.renderer.createElement("span");
      this.renderer.setAttribute(blockIcon, "class", blockIconClassUnlocked);
      this.renderer.appendChild(blockIconDiv, blockIcon);

      this.renderer.appendChild(tabElement, blockIconDiv);

      this.renderer.listen(blockIconDiv, "click", () => {
        this.blockTab(content.tab.contentItem, true);
        this._goldenLayout.focusComponent(content.tab.contentItem, false);
      });
    }

    const blockIcon = tabElement.querySelector(`.${tabBlockerClass} span`) as HTMLElement;

    // Gestionar estado de bloqueo
    const container = content.container;
    const state = (container.getState() as GLTabState) || {};

    let isLocked = state.locked;

    if (reset) {
      isLocked = !state.locked;
      container.setState({ locked: isLocked });
    }

    const newClass = isLocked ? blockIconClassLocked : blockIconClassUnlocked;
    this.renderer.setAttribute(blockIcon, "class", newClass);
  }


  /**
   * Focuses a given tab and updates its visual state.
   *
   * @param tab - The tab to be focused.
   * @param isInit - A boolean indicating if this is the initial focus. Defaults to true.
   *
   * This method performs the following actions:
   * - If `isInit` is true, it creates and inserts an active icon element into the tab.
   * - It updates the state of all currently focused components to unfocused.
   * - It updates the state of the given tab to focused and applies the appropriate CSS classes.
   */
  private focusTab(tab: Tab, isInit = true): void {
    const tabElement = tab.element;
    const activeIconClass = "tabActiveIcon pi pi-eye";
    const focusClass = "tabFocus tabActive";
    const focusHiddenClass = "tabFocus tabActiveHidden";

    if (isInit) {
      const activeIconDiv = this.renderer.createElement("div");
      const activeIcon = this.renderer.createElement("span");
      this.renderer.setAttribute(activeIcon, "class", activeIconClass);
      this.renderer.appendChild(activeIconDiv, activeIcon);

      this.renderer.insertBefore(tabElement, activeIconDiv, tabElement.firstChild);
    }

    this.getComponentItems()
      .filter((c) =>
        (c.tab.element.firstChild as HTMLElement)?.classList.contains("tabActive")
      )
      .forEach((focusedComponent) => {
        const focusedElement = focusedComponent.tab.element.firstChild as HTMLElement;
        this.renderer.setAttribute(focusedElement, "class", focusHiddenClass);
        const state = (focusedComponent.tab.componentItem.container.getState() as GLTabState) || {};
        state.focused = false;
        focusedComponent.tab.componentItem.container.setState(state);
        // (focusedComponent.tab.componentItem.container.state as any).focused = false;
      });

    const currentState = (tab.componentItem.container.getState() as GLTabState) || {};

    const isFocused = currentState.focused || !isInit;
    const focusClassToApply = isFocused ? focusClass : focusHiddenClass;

    currentState.focused = isFocused;

    tab.componentItem.container.setState(currentState);

    this.renderer.setAttribute(tabElement.firstChild as HTMLElement, "class", focusClassToApply);
  }


  /**
   * Retrieves the currently active (focused) component item within the Golden Layout.
   *
   * @returns {ComponentItem | undefined} The active component item if one is focused, otherwise undefined.
   */
  getActiveItem(): ComponentItem | undefined {
    if (this._goldenLayout) {
      const focusedItem = this.getComponentItems().find(
        (c) => (c.container.getState() as GLTabState).focused == true
      );
      if (focusedItem) this._goldenLayout.focusComponent(focusedItem, false);

      return this._goldenLayout.focusedComponentItem;
    }
    return;
  }

  private getComponentItems(): ComponentItem[] {
    const components: ComponentItem[] = [];

    this.findStacksAndComponentsRecursively(
      this._goldenLayout.rootItem,
      [],
      components
    );
    return components;
  }

  private contentItemIsStack(item: ContentItem): item is Stack {
    return (item as Stack).isStack === true;
  }

  private findStacksAndComponentsRecursively(
    item: ContentItem | undefined,
    stacks: Stack[],
    components: ContentItem[]
  ) {
    if (item) {
      if (this.contentItemIsStack(item)) {
        stacks.push(item);
        if (item.contentItems && item.contentItems.length > 0) {
          components.push(...item.contentItems);
        }
      } else if (item.contentItems) {
        item.contentItems.forEach((subItem) => {
          this.findStacksAndComponentsRecursively(subItem, stacks, components);
        });
      }
    }
  }

  onTabCreated = (newTab: Tab) => {
    if (!this.allwaysOpenNewTabOnGL) {
      this.blockTab(newTab.componentItem, false);
    } else {
      this.renderer.addClass(newTab.componentItem.tab.element, "noLock");
    }
    this.focusTab(newTab, true);
  };

  onFocus = (event: any) => {
    const component = event.target;
    if (component instanceof ComponentItem) {
      this._goldenLayout.focusComponent(component, false);
      this.focusTab(component.tab, false);
    }
  };

  onActiveContentItemChanged = (activeContentItem: ComponentItem) => {
    this._goldenLayout.focusComponent(activeContentItem, false);
  };

  onItemDropped = (item: ComponentItem) => {
    this.blockTab(item, false);
    this.focusTab(item.tab, false);
  };

  onStateChanged = () => {
    if (this._goldenLayout?.isInitialised) {
      const state = this._goldenLayout.saveLayout();
      // Don't save in local storage GoldenLayout layout configuration when using disableTabs
      if (state.header.show) {
        localStorage.setItem(GL_LOCALSTORAGE_STATE, JSON.stringify(state));
      }
    }
  };

  private setLayoutEvents() {
    this._goldenLayout.on("tabCreated", this.onTabCreated);
    this._goldenLayout.on("activeContentItemChanged", this.onActiveContentItemChanged);
    this._goldenLayout.on("itemDropped", this.onItemDropped);
    this._goldenLayout.on("focus", this.onFocus);
    this._goldenLayout.on("stateChanged", this.onStateChanged);
  }

  private unsetLayoutEvents() {
    this._goldenLayout.off("tabCreated", this.onTabCreated);
    this._goldenLayout.off("activeContentItemChanged", this.onActiveContentItemChanged);
    this._goldenLayout.off("itemDropped", this.onItemDropped);
    this._goldenLayout.off("focus", this.onFocus);
    this._goldenLayout.off("stateChanged", this.onStateChanged);
  }

}