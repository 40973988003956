import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
//import { Department } from "../models/bussinesOrganization/department.model";
import { ConfigDefaultFilters } from "../models/configDefaultFilters.model";
//import { Employee } from "../models/employees/employee.model";
import { FilterInstance, Filters } from "../models/filters.model";
import { GeneralData } from "../models/generalData.model";
//import { InfoView } from "../models/infoView.model";
//import { Category } from "../models/software/aplications/category.model";
import { BaseService, ConfigsService } from "@overa/shared";

@Injectable({ providedIn: "root" })
export class FiltersService extends BaseService<any> {
  [key: string]: any;
  override get apiBaseUrl(): string {
    return this.config.getModuleConfig("overa-security", "apiBaseUrl") + "/api";
  }

  override get path(): string {
    return "/filterConfiguration";
  }

  private get filtersApiUrl(): string {
    return this.apiBaseUrl + this.path;
  }

  constructor(
    public override http: HttpClient,
    protected override config: ConfigsService
  ) {
    super(http, config);
  }

  getViewFilters(view: any): Observable<FilterInstance[]> {
    //return this.http.get<FilterInstance[]>(`${this.filtersApiUrl}`);

    const results: FilterInstance[] = [
      //#region view: "GlobalFiltersComponent", filterType: "default"
      {
        name: "filterType",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "filterTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "name",
        defaultValue: "default",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "startDate",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null, //new Date("02/01/24"),"02/01/24",-1,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],

        previousBtn: true,
        nextBtn: false,
        //showtime: true,
      },
      {
        name: "endDate",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null, //new Date("02/01/24"),"02/01/24",-1,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],

        previousBtn: false,
        nextBtn: true,
        //showtime: true,
      },
      {
        name: "wildcardFilterString",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "input", //input
        required: false,
        optionLabel: "name",
        defaultValue: "", //"1-Global-Default",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "uids",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "uids",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: "", //"yyqlrxs8guopi1riz1ub9",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "departments",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "departments",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "functions",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "functions",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: [], //"35246772-a505-49e7-bf3c-08d97dae1799","662e275c-a91d-4089-de55-08da427217d0","ee4417dc-ab95-461b-de56-08da427217d0""6c632e14-5733-410d-de57-08da427217d0","eacf0225-53bc-4469-de58-08da427217d0",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "roles",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "roles",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "description",
        defaultValue: [], //085dae0a-01e6-4cf7-88fe-08d87f1cd1ad
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "employees",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "employees",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null, //"1c76a346-e46d-4dcd-0dc2-08dadb5eb6e3","bb2e66a6-11be-4dc7-e4fc-08daea5d7839"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "hostNames",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "hosts",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: ["black-w10", "black-w11"], //"black-w10","black-w11","black-ws2016",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      //*ngIf="checkFilterVisibleWildcar(filtersInstance.hostNames)"
      //filterWildCarHost
      {
        name: "userNames",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "users",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: ["administrador", "administrator"], //"administrador","administrator",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "singleApps",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "singleApps",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: ["7zg.exe", "applicationframehost.exe"], //"7zg.exe","applicationframehost.exe",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "employeeTag",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "employeeTags",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: [
          "489980ad-2e25-4742-4512-08dac187b038",
          "4f80240a-3697-47fa-8127-08db6cf4fc8f",
        ], //"489980ad-2e25-4742-4512-08dac187b038","4f80240a-3697-47fa-8127-08db6cf4fc8f",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "taskTypes",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "taskTypes",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "translatedName",
        defaultValue: ["0", "1", "2"], //"0","1","2"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "taskStatus",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "tasksStatus",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "translatedName",
        defaultValue: ["0", "1", "2"], //"0","1","2"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "types",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "types",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: ["afk", "alive"], //"afk","alive",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "multipleStatus",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "status",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: ["default", "enabled", "disabled", "pending"], //"default","enabled","disabled","pending",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "processedType",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "measurementProcessedTypes",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: ["activityindicators", "collaborativemeasurement"], //"activityindicators","collaborativemeasurement",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "systemModels",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "deviceModels",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: ["_unknown_", "asus p1512cea_p1512cea"], //"_unknown_","asus p1512cea_p1512cea",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "appVersions",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "appVersions",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: ["0.14.19", "0.14.35"], //"0.14.19","0.14.35",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "deviceTypes",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "deviceTypes",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: ["pc", "mobile"], //"pc","mobile"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "processNames",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "processes",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: [
          "121.0.6167.85_120.0.6099.225_chrome_updater.exe",
          "abmsvchost.exe",
        ], //"121.0.6167.85_120.0.6099.225_chrome_updater.exe","abmsvchost.exe",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      //*ngIf="checkFilterVisibleWildcar(filtersInstance.processNames)"
      //filterWildCarProcess
      {
        name: "timeInterval",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "timeIntervals",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "", //"10","30","60"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "timeIntervalMinutes",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "timeIntervalsMinutes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "5", //"-1","5","15","30","60","300"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "timeIntervalDays",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "timeIntervalsDays",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "2880", //"1440","2880","7200","10080","21600","43200"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "minDuration",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "durationIntervalsHours",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "0", //"0","3600"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "maxDuration",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "durationIntervalsHours",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "3600", //"0","3600"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "productivityType",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "productivityTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "ALL", //"PRODUCTIVE","NOT_PRODUCTIVE","ALL",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "dayTypes",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "dayTypes",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: ["8cf9901a-e2bf-4dba-92b1-08d90ae1fedc"], //"2e15471b-6622-47a0-92b0-08d90ae1fedc","8cf9901a-e2bf-4dba-92b1-08d90ae1fedc"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "softwareProducts",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "softwareProducts",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: [
          "f22706df-d332-4714-7c3d-08dac0c96e58",
          "fe3cebd3-6c5f-4e74-8b0c-08dac1929cfd",
        ], //"f22706df-d332-4714-7c3d-08dac0c96e58","fe3cebd3-6c5f-4e74-8b0c-08dac1929cfd",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "measureType",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "measureTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "sum", //"average","median","sum",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "groupType",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "groupTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "function", //"department","function"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "ignoreSystemUsers",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "yesOrNo",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "1", //"0","1"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "excludeZeroValues",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "yesOrNo",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "1", //"0","1"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "showInfoType",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "showInfoTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "1", //"0","1","2"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "withinWorkingHours",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "yesOrNoOrAll",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "true", //true,false,null
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "isPercentage",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "trueOrFalse",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "true", //true,false
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "dataType",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "dataTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "dataTypeDevice", //"dataTypeDevice","dataTypeUser"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "screenType",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "screenTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "screenTypeAnomalies", //"screenTypeLastSignals""screenTypeAnomalies"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "numItems",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "numItems",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "100", //"200","100","50","25","10","5","0"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "viewTelemetry",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "yesOrNo",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "1", //"0","1"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "hostsInformation",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "hostsInformations",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "hostsInformationSo", //"hostsInformationCpu","hostsInformationGpu","hostsInformationRam","hostsInformationSo"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "tierTop",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "tierTops",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "3", //"-1","3","5","10","15"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "softwareDataType",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "softwareDataTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "cpuUsageAvg", //"usages","duration","focusUsages","focusDuration","ramUsageAvg","cpuUsageAvg"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "showAllProducts",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "yesOrNo",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "1", //"0","1"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "showDeviceOrUser",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "deviceOrUser",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "device", //"device","user"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "windowsEventsId",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "windowsEventsIds",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "translatedName",
        defaultValue: ["0", "1"], //"0","1"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "windowsEventsChannel",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "windowsEventsChannels",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "translatedName",
        defaultValue: ["Application", "Security", "System"], //"Application","Security","System",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "windowsEventsLevel",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "windowsEventsLevels",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "translatedName",
        defaultValue: ["2"], //"2"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "hostNameTag",
        view: "GlobalFiltersComponent",
        filterType: "default",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "hostNameTags",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "translatedName",
        defaultValue: [
          "f287b101-5502-4dca-c543-08db8c2324b2",
          "56e30f94-c9cf-45bf-c545-08db8c2324b2",
        ], //"f287b101-5502-4dca-c543-08db8c2324b2","56e30f94-c9cf-45bf-c545-08db8c2324b2",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      //#region view: "GlobalFiltersComponent", filterType: "user"
      {
        name: "filterType",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "filterTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "name",
        defaultValue: "user",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "startDate",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: -1, //new Date("02/01/24"),
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],

        previousBtn: true,
        nextBtn: false,
        //showtime: true,
      },
      {
        name: "endDate",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: -1,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],

        previousBtn: false,
        nextBtn: true,
        //showtime: true,
      },
      {
        name: "wildcardFilterString",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "",
        type: "input", //input
        required: false,
        optionLabel: "name",
        defaultValue: "1-Global-user",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "uids",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "uids",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: "yyqlrxs8guopi1riz1ub9",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "departments",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "departments",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "functions",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "functions",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "roles",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "roles",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "description",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "employees",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "employees",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "hostNames",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "hosts",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      //*ngIf="checkFilterVisibleWildcar(filtersInstance.hostNames)"
      //filterWildCarHost
      {
        name: "userNames",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "users",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "singleApps",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "singleApps",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "employeeTag",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "employeeTags",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "taskTypes",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "taskTypes",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "taskStatus",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "tasksStatus",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "types",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "types",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "multipleStatus",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "status",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "processedType",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "measurementProcessedTypes",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "systemModels",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "deviceModels",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "appVersions",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "appVersions",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "deviceTypes",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "deviceTypes",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "processNames",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "processes",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      //*ngIf="checkFilterVisibleWildcar(filtersInstance.processNames)"
      //filterWildCarProcess
      {
        name: "timeInterval",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "timeIntervals",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "60",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "timeIntervalMinutes",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "timeIntervalsMinutes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "5",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "timeIntervalDays",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "timeIntervalsDays",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "2880",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "minDuration",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "durationIntervalsHours",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "maxDuration",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "durationIntervalsHours",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      //#endregion
      {
        name: "productivityType",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "productivityTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "ALL",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "dayTypes",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "dayTypes",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "softwareProducts",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "softwareProducts",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "measureType",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "measureTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "sum",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "groupType",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "groupTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "ignoreSystemUsers",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "yesOrNo",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "excludeZeroValues",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "yesOrNo",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "showInfoType",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "showInfoTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "withinWorkingHours",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "yesOrNoOrAll",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "isPercentage",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "trueOrFalse",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "dataType",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "dataTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "screenType",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "screenTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "numItems",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "numItems",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "viewTelemetry",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "yesOrNo",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "hostsInformation",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "hostsInformations",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "tierTop",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "tierTops",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "3",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "softwareDataType",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "softwareDataTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "showAllProducts",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "yesOrNo",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "1",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "showDeviceOrUser",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "deviceOrUser",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "device",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "windowsEventsId",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "windowsEventsIds",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "windowsEventsChannel",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "windowsEventsChannels",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "windowsEventsLevel",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "windowsEventsLevels",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "hostNameTag",
        view: "GlobalFiltersComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "hostNameTags",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "filterType",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "filterTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "name",
        defaultValue: "rol",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "startDate",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: -1, //new Date("02/01/24"),
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],

        previousBtn: true,
        nextBtn: false,
        //showtime: true,
      },
      {
        name: "endDate",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: -1,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],

        previousBtn: false,
        nextBtn: true,
        //showtime: true,
      },
      {
        name: "wildcardFilterString",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "input", //input
        required: false,
        optionLabel: "name",
        defaultValue: "1-Global-Rol",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "uids",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "uids",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "departments",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "departments",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "functions",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "functions",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "roles",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "roles",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "description",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "employees",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "employees",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "hostNames",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "hosts",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      //*ngIf="checkFilterVisibleWildcar(filtersInstance.hostNames)"
      //filterWildCarHost
      {
        name: "userNames",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "users",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "singleApps",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "singleApps",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "employeeTag",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "employeeTags",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "taskTypes",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "taskTypes",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "taskStatus",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "tasksStatus",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "types",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "types",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "multipleStatus",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "status",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "processedType",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "measurementProcessedTypes",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "systemModels",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "deviceModels",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "appVersions",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "appVersions",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "deviceTypes",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "deviceTypes",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "processNames",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "processes",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      //*ngIf="checkFilterVisibleWildcar(filtersInstance.processNames)"
      //filterWildCarProcess
      {
        name: "timeInterval",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "timeIntervals",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "60",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "timeIntervalMinutes",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "timeIntervalsMinutes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "5",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "timeIntervalDays",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "timeIntervalsDays",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "2880",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "minDuration",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "durationIntervalsHours",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "maxDuration",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "durationIntervalsHours",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "productivityType",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "productivityTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "ALL",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "dayTypes",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "dayTypes",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "softwareProducts",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "softwareProducts",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "measureType",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "measureTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "sum",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "groupType",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "groupTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "ignoreSystemUsers",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "yesOrNo",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "excludeZeroValues",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "yesOrNo",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "showInfoType",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "showInfoTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "withinWorkingHours",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "yesOrNoOrAll",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "isPercentage",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "trueOrFalse",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "dataType",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "dataTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "screenType",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "screenTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "numItems",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "numItems",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "viewTelemetry",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "yesOrNo",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "hostsInformation",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "hostsInformations",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "tierTop",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "tierTops",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "3",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "softwareDataType",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "softwareDataTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "showAllProducts",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "yesOrNo",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "1",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "showDeviceOrUser",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "deviceOrUser",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "device",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "windowsEventsId",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "windowsEventsIds",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "windowsEventsChannel",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "windowsEventsChannels",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "windowsEventsLevel",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "windowsEventsLevels",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "hostNameTag",
        view: "GlobalFiltersComponent",
        filterType: "rol",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "hostNameTags",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "translatedName",
        defaultValue: null,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "filterType",
        view: "UserSummaryComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "filterTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "name",
        defaultValue: "global",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "startDate",
        view: "UserSummaryComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null, //new Date("02/01/24"),
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],

        previousBtn: true,
        nextBtn: false,
        //showtime: true,
      },
      {
        name: "endDate",
        view: "UserSummaryComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: true, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],

        previousBtn: false,
        nextBtn: true,
        //showtime: true,
      },
      {
        name: "departments",
        view: "UserSummaryComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "departments",
        type: "multiSelect",
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false,
        filter: false,
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "filterType",
        view: "UserSummaryComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "filterTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "name",
        defaultValue: "user",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "startDate",
        view: "UserSummaryComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: -1, //new Date("02/01/24"),
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],

        previousBtn: true,
        nextBtn: false,
        //showtime: true,
      },
      {
        name: "endDate",
        view: "UserSummaryComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: -1,
        showClear: true, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],

        previousBtn: false,
        nextBtn: true,
        //showtime: true,
      },

      {
        name: "filterType",
        view: "UserSummaryUrlComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "filterTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "name",
        defaultValue: "global",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "startDate",
        view: "UserSummaryUrlComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null, //new Date("02/01/24"),
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],

        previousBtn: true,
        nextBtn: false,
        //showtime: true,
      },
      {
        name: "endDate",
        view: "UserSummaryUrlComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: true, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],

        previousBtn: false,
        nextBtn: true,
        //showtime: true,
      },
      {
        name: "departments",
        view: "UserSummaryUrlComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "departments",
        type: "multiSelect",
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false,
        filter: false,
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "filterType",
        view: "UserSummaryUrlComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "filterTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "name",
        defaultValue: "user",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "startDate",
        view: "UserSummaryUrlComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: -1, //new Date("02/01/24"),
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],

        previousBtn: true,
        nextBtn: false,
        //showtime: true,
      },
      {
        name: "endDate",
        view: "UserSummaryUrlComponent",
        filterType: "user",
        rolId: "",
        userId: "34f1e2a7-a4b8-46cc-0587-08dc37aaabe6",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: -1,
        showClear: true, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],

        previousBtn: false,
        nextBtn: true,
        //showtime: true,
      },

      {
        name: "filterType",
        view: "UserActivitySummary2WeeksComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "filterTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "name",
        defaultValue: "global",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "startDate",
        view: "UserActivitySummary2WeeksComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null, //new Date("02/01/24"),
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
        dataRangeDays: 14,
        previousBtn: true,
        nextBtn: true,
        //showtime: true,
      },
      {
        name: "endDate",
        view: "UserActivitySummary2WeeksComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: true, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],

        previousBtn: false,
        nextBtn: true,
        //showtime: true,
      },
      {
        name: "departments",
        view: "UserActivitySummary2WeeksComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "departments",
        type: "multiSelect",
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false,
        filter: false,
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "employees",
        view: "UserActivitySummary2WeeksComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "employees",
        type: "dropdown", //dropdown o multiSelect
        required: true,
        optionLabel: "name",
        defaultValue: null, //"1c76a346-e46d-4dcd-0dc2-08dadb5eb6e3","bb2e66a6-11be-4dc7-e4fc-08daea5d7839"
        showClear: true, //solo aplica en type:dropdown
        filter: true, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },

      {
        name: "filterType",
        view: "TelecommutingIndicatorsComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "filterTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "name",
        defaultValue: "global",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "startDate",
        view: "TelecommutingIndicatorsComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null, //new Date("02/01/24"),
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
        dataRangeDays: 14,
        previousBtn: true,
        nextBtn: true,
        //showtime: true,
      },
      {
        name: "endDate",
        view: "TelecommutingIndicatorsComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: true, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],

        previousBtn: false,
        nextBtn: true,
        //showtime: true,
      },
      {
        name: "departments",
        view: "TelecommutingIndicatorsComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "departments",
        type: "multiSelect",
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false,
        filter: false,
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "employees",
        view: "TelecommutingIndicatorsComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "employees",
        type: "dropdown", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null, //"1c76a346-e46d-4dcd-0dc2-08dadb5eb6e3","bb2e66a6-11be-4dc7-e4fc-08daea5d7839"
        showClear: true, //solo aplica en type:dropdown
        filter: true, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },

      {
        name: "filterType",
        view: "PercentageUsersTelemetryComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "filterTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "name",
        defaultValue: "global",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "startDate",
        view: "PercentageUsersTelemetryComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null, //new Date("02/01/24"),
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
        dataRangeDays: 14,
        previousBtn: true,
        nextBtn: true,
        //showtime: true,
      },
      {
        name: "endDate",
        view: "PercentageUsersTelemetryComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: true, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],

        previousBtn: false,
        nextBtn: true,
        //showtime: true,
      },

      {
        name: "filterType",
        view: "DigitalDisconnectComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "filterTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "name",
        defaultValue: "global",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "startDate",
        view: "DigitalDisconnectComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null, //new Date("02/01/24"),
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
        dataRangeDays: 14,
        previousBtn: true,
        nextBtn: true,
        //showtime: true,
      },
      {
        name: "endDate",
        view: "DigitalDisconnectComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: true, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],

        previousBtn: false,
        nextBtn: true,
        //showtime: true,
      },
      {
        name: "departments",
        view: "DigitalDisconnectComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "departments",
        type: "multiSelect",
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false,
        filter: false,
        visible: true,
        conditionalsRequired: [],
      },

      {
        name: "filterType",
        view: "UserActivitySummaryComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "filterTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "name",
        defaultValue: "global",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },

      {
        name: "startDate",
        view: "UserActivitySummaryComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null, //new Date("02/01/24"),
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
        previousBtn: true,
        nextBtn: false,
        //showtime: true,
      },
      {
        name: "endDate",
        view: "UserActivitySummaryComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: true, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],

        previousBtn: false,
        nextBtn: true,
        //showtime: true,
      },
      {
        name: "departments",
        view: "UserActivitySummaryComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "departments",
        type: "multiSelect",
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false,
        filter: false,
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "employees",
        view: "UserActivitySummaryComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "employees",
        type: "dropdown", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null, //"1c76a346-e46d-4dcd-0dc2-08dadb5eb6e3","bb2e66a6-11be-4dc7-e4fc-08daea5d7839"
        showClear: true, //solo aplica en type:dropdown
        filter: true, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "measureType",
        view: "UserActivitySummaryComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "measureTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "sum", //"average","median","sum",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },

      {
        name: "filterType",
        view: "UserActivitySummaryGroupedComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "filterTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "name",
        defaultValue: "global",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },

      {
        name: "startDate",
        view: "UserActivitySummaryGroupedComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null, //new Date("02/01/24"),
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
        previousBtn: true,
        nextBtn: false,
        //showtime: true,
      },
      {
        name: "endDate",
        view: "UserActivitySummaryGroupedComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: true, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],

        previousBtn: false,
        nextBtn: true,
        //showtime: true,
      },
      {
        name: "departments",
        view: "UserActivitySummaryGroupedComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "departments",
        type: "multiSelect",
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false,
        filter: false,
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "employees",
        view: "UserActivitySummaryGroupedComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "employees",
        type: "dropdown", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null, //"1c76a346-e46d-4dcd-0dc2-08dadb5eb6e3","bb2e66a6-11be-4dc7-e4fc-08daea5d7839"
        showClear: true, //solo aplica en type:dropdown
        filter: true, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "measureType",
        view: "UserActivitySummaryGroupedComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "measureTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "sum", //"average","median","sum",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "groupType",
        view: "UserActivitySummaryGroupedComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "groupTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "department", //"department","function"
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },

      // ProductiveWeek
      {
        name: "filterType",
        view: "ProductiveWeekComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "filterTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "name",
        defaultValue: "global",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },

      {
        name: "startDate",
        view: "ProductiveWeekComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null, //new Date("02/01/24"),
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
        previousBtn: true,
        nextBtn: false,
        //showtime: true,
      },
      {
        name: "endDate",
        view: "ProductiveWeekComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: true, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],

        previousBtn: false,
        nextBtn: true,
        //showtime: true,
      },
      {
        name: "departments",
        view: "ProductiveWeekComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "departments",
        type: "multiSelect",
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false,
        filter: false,
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "employees",
        view: "ProductiveWeekComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "employees",
        type: "dropdown", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null, //"1c76a346-e46d-4dcd-0dc2-08dadb5eb6e3","bb2e66a6-11be-4dc7-e4fc-08daea5d7839"
        showClear: true, //solo aplica en type:dropdown
        filter: true, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "measureType",
        view: "ProductiveWeekComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "measureTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "sum", //"average","median","sum",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      // UserStatusSummaryOneLineComponent
      {
        name: "filterType",
        view: "UserStatusSummaryOneLineComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "filterTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "name",
        defaultValue: "global",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },

      {
        name: "startDate",
        view: "UserStatusSummaryOneLineComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null, //new Date("02/01/24"),
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
        previousBtn: true,
        nextBtn: true,
        showtime: false,
      },
      {
        name: "departments",
        view: "UserStatusSummaryOneLineComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "departments",
        type: "multiSelect",
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false,
        filter: false,
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "employees",
        view: "UserStatusSummaryOneLineComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "employees",
        type: "multiSelect", //dropdown o multiSelect
        required: true,
        optionLabel: "name",
        defaultValue: null, //"1c76a346-e46d-4dcd-0dc2-08dadb5eb6e3","bb2e66a6-11be-4dc7-e4fc-08daea5d7839"
        showClear: true, //solo aplica en type:dropdown
        filter: true, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "withinWorkingHours",
        view: "UserStatusSummaryOneLineComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "yesOrNoOrAll",
        type: "dropdown",
        required: false,
        optionLabel: "translatedName",
        defaultValue: "true", //true,false,null
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      // ProductiveVsAfkComponent
      {
        name: "filterType",
        view: "ProductiveVsAfkComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "filterTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "name",
        defaultValue: "global",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "startDate",
        view: "ProductiveVsAfkComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null, //new Date("02/01/24"),
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
        previousBtn: true,
        nextBtn: true,
        showtime: false,
      },
      {
        name: "endDate",
        view: "ProductiveVsAfkComponent",
        filterType: "global",
        rolId: "085dae0a-01e6-4cf7-88fe-08d87f1cd1ad",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: -1, //new Date("02/01/24"),"02/01/24",-1,
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],

        previousBtn: false,
        nextBtn: true,
        //showtime: true,
      },
      {
        name: "departments",
        view: "ProductiveVsAfkComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "departments",
        type: "multiSelect",
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false,
        filter: false,
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "employees",
        view: "ProductiveVsAfkComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "employees",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null, //"1c76a346-e46d-4dcd-0dc2-08dadb5eb6e3","bb2e66a6-11be-4dc7-e4fc-08daea5d7839"
        showClear: true, //solo aplica en type:dropdown
        filter: true, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "measureType",
        view: "ProductiveVsAfkComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "measureTypes",
        type: "dropdown", //dropdown
        required: true,
        optionLabel: "translatedName",
        defaultValue: "sum", //"average","median","sum",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "isPercentage",
        view: "ProductiveVsAfkComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "trueOrFalse",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "translatedName",
        defaultValue: "true", //true,false
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      // DeviceCommandsComponent
      {
        name: "filterType",
        view: "DeviceCommandsComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "filterTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "name",
        defaultValue: "global",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "startDate",
        view: "DeviceCommandsComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null, //new Date("02/01/24"),
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
        previousBtn: true,
        nextBtn: false,
        //showtime: true,
      },
      {
        name: "endDate",
        view: "DeviceCommandsComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "",
        type: "calendar", //calendar
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: true, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],

        previousBtn: false,
        nextBtn: true,
        //showtime: true,
      },
      {
        name: "departments",
        view: "DeviceCommandsComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "departments",
        type: "multiSelect",
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false,
        filter: false,
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "employees",
        view: "DeviceCommandsComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "employees",
        type: "dropdown", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: null, //"1c76a346-e46d-4dcd-0dc2-08dadb5eb6e3","bb2e66a6-11be-4dc7-e4fc-08daea5d7839"
        showClear: true, //solo aplica en type:dropdown
        filter: true, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
      // OveraCommandConsoleComponent
      {
        name: "filterType",
        view: "OveraCommandConsoleComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "filterTypes",
        type: "dropdown", //dropdown
        required: false,
        optionLabel: "name",
        defaultValue: "global",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: false,
        conditionalsRequired: [],
      },
      {
        name: "departments",
        view: "OveraCommandConsoleComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "departments",
        type: "multiSelect",
        required: false,
        optionLabel: "name",
        defaultValue: null,
        showClear: false,
        filter: false,
        visible: true,
        conditionalsRequired: [],
      },
      {
        name: "hostNames",
        view: "OveraCommandConsoleComponent",
        filterType: "global",
        rolId: "",
        userId: "",
        isInit: true,
        endpointName: "hosts",
        type: "multiSelect", //dropdown o multiSelect
        required: false,
        optionLabel: "name",
        defaultValue: ["black-w10", "black-w11"], //"black-w10","black-w11","black-ws2016",
        showClear: false, //solo aplica en type:dropdown
        filter: false, //solo aplica en type:dropdown
        visible: true,
        conditionalsRequired: [],
      },
    ];

    return of(results);
  }
}
