import { ActivityCommandValue } from "./activityCommandValue.model";

export class ActivityCommandField {
  id?: string;
  name: string;
  order?: number;
  type: ActivityCommandFieldType = ActivityCommandFieldType.INPUT;
  //defaultValue?: string;
  options?: ActivityCommandValue[]; // Para campos de tipo dropdown. NOTA: Solo se muestran los items usando objetos
  placeholder?: string;
  tooltip?: string;
  isFixed?: boolean;
  //isExtensible?: boolean;
  //isMultiple?: boolean;
  isRequired?: boolean;
  values?:
    | string
    | ActivityCommandValue
    | BooleanOption
    | ActivityCommandValue[];

  constructor(name: string) {
    this.values = this.type == ActivityCommandFieldType.MULTI ? [] : "";
    this.name = name;
  }
}

/**
 * Types of fields inside "data" property of Command object.
 * text and multitext assign a string to "data"
 * json assign a string to "data" removing spaces and line breaks
 */
export enum ActivityCommandFieldType {
  INPUT = "input", //{"command":"command","data":"{\"input\":\"2.1.2832\"}"}
  MULTI = "multi", //{"command":"command","data":"{\"multi\":[\"a\",\"b\"]}"}
  NUMBER = "number", //{"command":"command","data":"{\"number\":100}"}
  JSON = "json", //{"command":"command","data":"{\"script\":\"ping 8.8.8.8\",\"oidc_tokens\":[\"storage_api\"]}"}
  JSON_PARAM = "json_param", //{"command":"command","data":"{\"output_store\":{"type": "RabbitQueueMessage"}}"}
  DROPDOWN = "dropdown", //{"command":"command","data":"{\"option\":\"selected_option"}"}
  TEXT = "text", //{"command":"command","data":"string_with_data"}
  MULTITEXT = "multitext", //{"command":"command","data":"string\r\nwith\"scaped characters\"\n"}
  BOOLEAN = "boolean", //{"command":"command","data":"{\"boolean\":true}"}}
  DATAVOID = "datavoid", //{"command":"command","data":"{}"}
}

export const booleanOptions: BooleanOption[] = [
  { label: "True", value: true },
  { label: "False", value: false },
];

export class BooleanOption {
  label?: string;
  value: boolean;

  constructor(label: string, value: boolean) {
    this.label = label;
    this.value = value;
  }
}
