<!-- TEMPLATE FOR DATATABLE BAR STATUS -->
<ng-template #statusBarTemplate let-data>
    <ng-container>
        <div class="progress-bar">
            <div [ngClass]="{'green-fill': getProgressPercentage(data) == 100, 'blue-fill': getProgressPercentage(data) != 100}"
                [ngStyle]="{'width.%': getProgressPercentage(data)}">
            </div>
        </div>
        <span>{{data}}</span>
    </ng-container>
</ng-template>

<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <mat-card-content>
                            <div class="container-fluid main-container">

                                <div class="container-individualFilters">
                                    <app-filters #filterCmp [filterInit]="filterInit" (loading)="setLoading($event)"
                                        (loadData)="loadData($event)" (filterChange)="changeFilter($event)"
                                        (exportOnClick)="csvExport($event)"
                                        (validAllRequiredFilters)="validAllRequiredFilters($event)"
                                        (viewModeChange)="viewModeChange($event)">
                                    </app-filters>
                                </div>

                                <!-- TODO: Reemplazar strings por traducciones -->

                                <div class="row mt-5 mb-5">
                                    <overa-datatable #dataTable [columns]="columns" [expandableRows]="true"
                                        [expandableColumns]="secondaryColumns"
                                        [expandableFirstColumnKey]="'controlCommandDeliveries'"
                                        [expandableProperty]="'controlCommandDeliveries'" [loading]="loading"
                                        [loadDataFunction]="$any(loadDataFunction)"
                                        [localizeLiterals]="datatableLiterals" [showPaginator]="true"
                                        title="Control Commands" [showGlobalFilter]="true" [rowsPerPage]="[10, 20]"
                                        [showExports]="false" [items]="data" [totalRecords]="totalRecords"
                                        [selectionMode]="'single'" [showDeleteButton]="true"
                                        [tableActions]="tableActions" (tableAction)="onTableAction($event)"
                                        [lazy]="false" [sortOrder]="1">
                                    </overa-datatable>
                                </div>

                            </div>
                        </mat-card-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>