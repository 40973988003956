import { Inject, Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LayoutModeService } from './layout-mode.service';
import { Subject, takeUntil } from 'rxjs';
import { Menu } from '@overa/security';
import { ACCOUNT_SERVICE_TOKEN, ConfigsService, IAccountService } from '@overa/shared';
import { GoldenLayoutManagerService } from '../components/golden-layout/golden-layout-manager.service';


@Injectable({
    providedIn: 'root',
})
export class ViewManagerService implements OnDestroy {

    private readonly destroy$ = new Subject<void>();

    private isNavigating: boolean = false;
    private lastUrl: string = '';

    private readonly useGoldenLayout: boolean = false;
    private goldenLayoutInitialized: boolean = false;
    private pendingUrl: string = '';


    constructor(
        private readonly router: Router,
        private readonly layoutModeService: LayoutModeService,
        @Inject(ACCOUNT_SERVICE_TOKEN) private readonly accountService: IAccountService,
        private readonly config: ConfigsService,
        private readonly _goldenLayoutManagerService: GoldenLayoutManagerService,
    ) {

        this.useGoldenLayout = this.config.getModuleConfig(
            "overa-shared",
            "useGoldenLayout"
        );

        this.isNavigating = false;

        if (this.useGoldenLayout) {
            this._goldenLayoutManagerService.getInitializedEvent().pipe(takeUntil(this.destroy$)).subscribe(() => {
                this.goldenLayoutInitialized = true;
                if (this.pendingUrl) {
                    this.navigateByUrlToView(this.pendingUrl);
                    this.lastUrl = this.pendingUrl;
                    this.pendingUrl = '';
                }
            });
        }


        this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (this.useGoldenLayout && !this.goldenLayoutInitialized) {
                    console.warn('VIEW-MANAGER-SERVICE - Golden Layout not initialized yet. Waiting for initialization. Pending URL:', event.url);
                    this.pendingUrl = event.url;
                } else {
                    this.navigateByUrlToView(event.url);
                    this.lastUrl = event.url;
                }
            }
        });
    }

    /**
     * Navigates to a specified URL and handles the view rendering based on the layout mode.
     * 
     * @param url - The URL to navigate to.
     * 
     * If the application is currently navigating (`isNavigating` is true), the function will return immediately.
     * 
     * If `useGoldenLayout` is true, the function will check if the component corresponding to the URL is registered
     * with the Golden Layout manager. If it is, it will set the layout mode to Golden Layout, open the URL in the 
     * Golden Layout, and log the action. If the component is not registered, it will set the layout mode to false,
     * navigate using the router, and log the action.
     * 
     * If `useGoldenLayout` is false, the function will set the layout mode to false, navigate using the router, 
     * and log the action.
     * 
     * The `isNavigating` flag is used to prevent multiple simultaneous navigations.
     */
    navigateByUrlToView(url: string) {

        url = this.removeQueryString(url);

        // If the application is currently navigating, return immediately
        // If the URL is the same as the last URL, return immediately
        // Exception: If the application is using Golden Layout, only control the navigation if the Golden Layout is initialized
        if (this.isNavigating || ((!this.useGoldenLayout) && this.lastUrl === url)) {
            console.log('VIEW-MANAGER-SERVICE - IS NAVIGATING ALREADY', this.lastUrl);
            return;
        }

        console.log("VIEW-MANAGER-SERVICE - Navigating to:", url);

        if (this.useGoldenLayout) {

            if (!this.goldenLayoutInitialized || !this._goldenLayoutManagerService.goldenLayout) {
                console.warn('VIEW-MANAGER-SERVICE - Golden Layout not initialized yet. Waiting for initialization. Pending URL:', url);
                this.pendingUrl = url;
                return;
            }

            const componentRegistered = this._goldenLayoutManagerService.findComponentByUrl(url);
            if (componentRegistered) {

                this.layoutModeService.setGoldenLayoutMode(true);

                const menuItems = this.accountService.currentUserMenus;
                const currentMenuView = Menu.findMenuItemByUrl(menuItems, url);
                const menuTitle = currentMenuView?.name;

                this._goldenLayoutManagerService.openUrl(
                    componentRegistered.componentName,
                    menuTitle,
                    componentRegistered.params
                );
                console.log(`VIEW-MANAGER-SERVICE - Loading component in golden-layout - Title: ${menuTitle} Url: ${url}`);
                console.log('VIEW-MANAGER-SERVICE - End navigation to:', url);

            } else {
                this.isNavigating = true;
                this.layoutModeService.setGoldenLayoutMode(false);
                this.router.navigateByUrl(url).catch((err) => {
                    console.error('VIEW-MANAGER-SERVICE - Error navigating to:', url, err);
                }).finally(() => {
                    this.isNavigating = false;
                    console.log('VIEW-MANAGER-SERVICE - End navigation to:', url);
                    this.lastUrl = '';
                });
                console.log('VIEW-MANAGER-SERVICE - Url not registered in golden-layout. Loading component in router-oulet:', url);
            }

        } else {
            this.isNavigating = true;
            this.layoutModeService.setGoldenLayoutMode(false);
            this.router.navigateByUrl(url).finally(() => {
                this.isNavigating = false;
                console.log('VIEW-MANAGER-SERVICE - End navigation to:', url);
                this.lastUrl = '';
            });
            console.log('VIEW-MANAGER-SERVICE - Loading component in router-oulet:', url);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private removeQueryString(url: string): string {
        if (url.includes("?")) {
            url = url.substring(0, url.indexOf("?"));
        }
        return url;
    }
}
