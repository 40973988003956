import {
    ComponentFactoryResolver,
    Injectable,
    Injector,
    StaticProvider,
    Type,
} from "@angular/core";
import { BaseComponentDirective } from "@overa/shared";
import { ComponentContainer, JsonValue } from "golden-layout";
import { AppModule } from "src/app/app.module";
import { GL_CONTAINER_INJECTION_TOKEN } from "./golden-layout-consts";
import { NGRouteInfo } from "./golden-layout-utils";

@Injectable({
    providedIn: "root",
})
export class GoldenLayoutComponentService {

    private readonly _componentTypeMap = new Map<string, Type<BaseComponentDirective>>()

    constructor(
        private readonly componentFactoryResolver: ComponentFactoryResolver,
    ) { }

    registerComponentType(
        name: string,
        componentType: Type<BaseComponentDirective>,
    ) {
        this._componentTypeMap.set(name, componentType)
    }

    registerComponentTypes(
        components: NGRouteInfo[],
    ) {
        for (const component of components) {
            this.registerComponentType(component.name, component.componentType)
        }
    }

    getRegisteredComponentTypeNames(): string[] {
        const count = this._componentTypeMap.size
        const result = new Array<string>(count)
        let idx = 0
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [key, _value] of this._componentTypeMap) {
            result[idx++] = key
        }
        return result
    }

    createComponent(
        componentTypeJsonValue: JsonValue,
        container: ComponentContainer,
    ) {
        const componentType = this._componentTypeMap.get(
            componentTypeJsonValue as string,
        )
        if (componentType === undefined) {
            throw new Error('Unknown component type')
        } else {
            const provider: StaticProvider = {
                provide: GL_CONTAINER_INJECTION_TOKEN,
                useValue: container,
            }
            const injector = Injector.create({
                providers: [provider],
                parent: AppModule.injector,
            })
            const componentFactoryRef =
                this.componentFactoryResolver.resolveComponentFactory<BaseComponentDirective>(
                    componentType,
                )
            return componentFactoryRef.create(injector)
        }
    }

}