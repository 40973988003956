import { Type } from "@angular/core";

/**
 * A utility class for matching URL patterns and extracting parameters from URLs.
 */
export class UrlPatternMatcher {

    /**
     * Trims leading and trailing slashes from the given string.
     * 
     * @param value - The string to trim.
     * @returns The trimmed string.
     */
    static trimSlash(value: string): string {
        if (value) {
            let v = value.startsWith("/") ? value.substring(1) : value;
            v = v.endsWith("/") ? v.substring(0, v.length - 1) : v;
            return v;
        }
        return "";
    }


    /**
     * Matches a URL against a pattern and extracts parameters.
     * 
     * @param pattern - The URL pattern to match, with parameters prefixed by ':'.
     * @param url - The URL to match against the pattern.
     * @returns An object containing the extracted parameters, or an empty object if the URL does not match the pattern.
     */
    static matchUrlPattern(pattern: string, url: string): { [key: string]: string } {
        const patternParts = pattern.split('/');
        const urlParts = url.split('/');

        if (patternParts.length !== urlParts.length) {
            return {};
        }

        const params: { [key: string]: string } = {};

        for (let i = 0; i < patternParts.length; i++) {
            if (patternParts[i].startsWith(':')) {
                const paramName = patternParts[i].substring(1);
                params[paramName] = urlParts[i];
            } else if (patternParts[i] !== urlParts[i]) {
                return {};
            }
        }

        return params;
    }

    /**
     * Checks if a URL matches a given pattern.
     * 
     * @param pattern - The URL pattern to match, with parameters prefixed by ':'.
     * @param url - The URL to check against the pattern.
     * @returns True if the URL matches the pattern, false otherwise.
     */
    static isMatch(pattern: string, url: string): boolean {
        const patternParts = pattern.split('/');
        const urlParts = url.split('/');

        if (patternParts.length !== urlParts.length) {
            return false;
        }

        return patternParts.every((part, index) => {
            if (part.startsWith(':')) {
                return true;
            }
            return part === urlParts[index];
        });
    }
}



/**
 * Interface representing the information required for an Angular route.
 */
export interface NGRouteInfo {
    /**
     * The name of the route.
     */
    name: string;

    /**
     * The component type associated with the route.
     */
    componentType: Type<any>;

    /**
     * Optional data to be passed with the route.
     */
    data?: any;

    /**
     * Optional array of parameter names for the route.
     */
    params?: string[];
}