import { Component, ViewChild, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { ACCOUNT_SERVICE_TOKEN, IAccountService } from "@overa/shared";
import { IframeOveraComponent } from "../iframeOveraComponent/iframeOveraComponent.component";

@Component({
    selector: "app-dashboard",
    templateUrl: "./signalDashboard.component.html",
    styleUrls: ["./signalDashboard.component.css"],
})
export class SignalDashboardComponent implements OnInit {
    private param: string | undefined;
    iframeDashboardUrl!: string;
    querySelectorsToRemove: string[] = [
        '[data-testid="data-testid Nav toolbar"]',
        '[data-testid="data-testid navigation mega-menu"]',
        '.css-1bgjk0t'
    ];
    dynamicStyles = [
        { selector: '.css-60onds', styles: { paddingTop: '0', display: '' } }, // Se asegura que `display` esté definido
        { selector: 'section[aria-label="Dashboard submenu"]', styles: { display: 'none', paddingTop: '' } } // Similar aquí
    ];
    constructor(
        private readonly router: Router, @Inject(ACCOUNT_SERVICE_TOKEN) private readonly accountService: IAccountService) { }
    ngOnInit() {

        // Get param from url to set iframe contents
        const url = this.router.url.toString();
        const urlSplit = url.split('/');
        this.param = urlSplit[urlSplit.length - 1];
        this.configureIframeSource();
    }



    @ViewChild(IframeOveraComponent) iframeComponent!: IframeOveraComponent;

    onVariablesData(variables: any[]): void {
        console.log('Variables dinámicas recibidas:', variables);
    }

    onAdhocFiltersData(adhocFilters: any[]): void {
        console.log('Filtros AdHoc recibidos:', adhocFilters);
    }

    eliminarFiltros() {
        this.iframeComponent.removeOfDOM();
    }

    aplicarFiltros() {
        this.iframeComponent.applyFilters();
    }

    private configureIframeSource(
    ) {
        const menuItems = this.accountService.currentUserMenus;
        let menu = menuItems.find(m => m.isIframe && m.uiUrl?.endsWith(this.param ?? ""));
        if (!menu) {
            menuItems.forEach(chilren => {
                chilren.children?.forEach(chilrens => {
                    if (chilrens.isIframe && chilrens.uiUrl?.endsWith(this.param ?? "")) {
                        menu = chilrens;
                    }
                });
            });
        }


        this.iframeDashboardUrl = menu?.iframeUrl ?? "";

    }
}