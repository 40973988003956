import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import {
  ApplicationRef,
  CUSTOM_ELEMENTS_SCHEMA,
  Injector,
  NgModule,
  isDevMode,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule, enableDebugTools } from "@angular/platform-browser";

import {
  AuthInterceptor,
  EncryptorInterceptor,
  ErrorHandlerInterceptor,
  TenantInterceptor,
  UserInterceptor,
} from "@overa/security";

import {
  InitModule,
  OveraAppComponent,
  OveraAppRoutingModule,
} from "@overa/base";

import {
  AutoTracingModule,
  GLOBALS_TOKEN,
  NgxTranslateService,
  OveraTranslateModule,
  UtcDateInterceptor,
} from "@overa/shared";

import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { APP_BASE_HREF, CommonModule } from "@angular/common";

import { ServiceWorkerModule } from "@angular/service-worker";
import { LayoutComponent } from "./components/layout.component";

import { FiltersComponent } from "./components/shared/filters/filters.component";
import { GlobalFiltersComponent } from "./components/shared/filters/globalFilters/globalFilters.component";
import { FilterDefaultDialog } from "./components/shared/filters/filterDefaultDialog/filterDefaultDialog.component";

import { UserSummaryComponent } from "./components/userActivity/userSummary/userSummary.component";
import { UserSummaryUrlComponent } from "./components/userActivity/userSummaryUrl/userSummaryUrl.component";
import { TelecommutingIndicatorsComponent } from "./components/indicators/telecommuting-indicators/telecommuting-indicators.component";
import { UserActivitySummary2WeeksComponent } from "./components/userActivity/user-activity-summary2-weeks/user-activity-summary2-weeks.component";
import { PercentageUsersTelemetryComponent } from "./components/audit/percentage-users-telemetry/percentageUsersTelemetry.component";
import { UserStatusSummaryOneLineComponent } from "./components/userActivity/userStatusSummaryOneLine/userStatusSummaryOneLine.component";
import { DigitalDisconnectComponent } from "./components/indicators/digitalDisconnect/digitalDisconnect.component";
import { SignalAnalysisComponent } from "./components/audit/signalAnalysis/signalAnalysis.component";
import { SignalDashboardComponent } from "./components/signals/signalDashboard/signalDashboard.component";
import { UserActivitySummaryGroupedComponent } from "./components/userActivity/user-activity-summary-grouped/user-activity-summary-grouped.component";
import { DetailedViewComponent } from "./components/userActivity/detailed-view/detailed-view.component";
import { DialogUpdateDetailComponent } from "./components/userActivity/detailed-view/dialog-update-detail/dialog-update-detail.component";
import { UserAnalisysComponent } from "./components/userActivity/userAnalisys/userAnalisys.component";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { AppConfig } from "./app.config";

import { OveraModule } from "./modules/overa.module";

import { RoutesSettings, RoutesService } from "./routes-settings";
import { UserActivitySummaryComponent } from "./components/userActivity/user-activity-summary/user-activity-summary.component";
import { DeviceProcessConsumptionComponent } from "./components/deviceActivity/device-process-consumption/device-process-consumption.component";
import { HomeComponent } from "./components/home/home.component";
import { PrimeNgModule } from "./modules/primeng.module";
import { ToastrModule } from "ngx-toastr";

import { OveraDataTableModule } from "@overa/components/datatable";
import {
  DYNAMIC_GUARD_TOKEN,
  DYNAMIC_MAINTENANCE_GUARD_TOKEN,
  OveraDynamicModule,
} from "@overa/dynamic/components";
import { ActivityDynamicMaintenancesGuard } from "./guards/activitydynamic-maintenances.guard";
import { ActivityDynamicGuard } from "./guards/activitydynamic.guard";
import { DialogService } from "primeng/dynamicdialog";
import { DeviceSignalReportComponent } from "./components/signals/device-signal-report/device-signal-report.component";
import { ProductiveUsageComponent } from "./components/indicators/productive-usage/productive-usage.component";
import { EntryExitComponent } from "./components/indicators/entry-exit/entry-exit.component";
import { ProductiveWeekComponent } from "./components/indicators/productive-week/productive-week.component";
import { ProductiveVsAfkComponent } from "./components/indicators/productive-vs-afk/productive-vs-afk.component";
import { AppUrlScheduleComponent } from "./components/userActivity/app-url-schedule/app-url-schedule.component";
import { ProcessConsumptionComponent } from "./components/deviceActivity/process-consumption/process-consumption.component";
import {
  NoPreloading,
  PreloadAllModules,
} from "@angular/router";
import { TerminalComponent } from "./components/deviceActivity/terminal/terminal.component";
import { OveraTerminalComponent } from "./components/shared/overa-terminal/overa-terminal.component";
import { DeviceCommandsComponent } from "./components/deviceActivity/device-commands/device-commands.component";
import { OveraCommandConsoleComponent } from "./components/shared/overa-command-console/overa-command-console.component";
import { OveraFiltersModule } from "@overa/components/filters";
import { ActivityGlobals } from "./utils/globals";
import { NgxSpinnerModule } from "ngx-spinner";
import { GoldenLayoutHostComponent } from "./components/golden-layout/golden-layout-host.component";
import { GoldenLayoutComponentService } from "./components/golden-layout/golden-layout-component.service";
import { environment } from "src/environments/environment";

import { IframeOveraComponent } from "./components/signals/iframeOveraComponent/iframeOveraComponent.component";

export function getBaseUrlFactory() {
  try {
    return document.getElementsByTagName("base")[0].href ?? "/";
  } catch {
    return "/";
  }
}

const menuService = new RoutesService(); // Inicialización manual del servicio
RoutesSettings.initialize(menuService); // Inicializa `MenuSettings` antes de configurar rutas


@NgModule({
  declarations: [
    LayoutComponent,
    HomeComponent,
    IframeOveraComponent,
    GoldenLayoutHostComponent,

    FiltersComponent,
    GlobalFiltersComponent,
    FilterDefaultDialog,
    SignalDashboardComponent,
    UserSummaryComponent,
    UserSummaryUrlComponent,
    TelecommutingIndicatorsComponent,

    UserActivitySummary2WeeksComponent,
    PercentageUsersTelemetryComponent,
    UserStatusSummaryOneLineComponent,
    DigitalDisconnectComponent,
    SignalAnalysisComponent,
    UserActivitySummaryGroupedComponent,
    DetailedViewComponent,
    DialogUpdateDetailComponent,
    UserAnalisysComponent,
    UserActivitySummaryComponent,
    DeviceProcessConsumptionComponent,
    DeviceProcessConsumptionComponent,
    DeviceSignalReportComponent,
    ProductiveUsageComponent,
    EntryExitComponent,
    ProductiveWeekComponent,
    ProductiveVsAfkComponent,
    AppUrlScheduleComponent,
    ProcessConsumptionComponent,
    TerminalComponent,
    OveraTerminalComponent,
    DeviceCommandsComponent,
    OveraCommandConsoleComponent,
  ],
  imports: [
    InitModule.setConfig(AppConfig),
    OveraTranslateModule.setConfig("es", [
      "calendar",
      "charts",
      "common",
      "views",
      "filters",
    ]),
    OveraDataTableModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    NgxSpinnerModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    OveraDynamicModule,
    OveraFiltersModule,

    OveraModule,
    PrimeNgModule,

    OveraAppRoutingModule.forRoot(
      RoutesSettings.ROUTES,
      false,
      PreloadAllModules, //NoPreloading,
      "reload"
    ),

    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
      timeOut: 5000,
      enableHtml: true,
    }),

    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),

    AutoTracingModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: DYNAMIC_GUARD_TOKEN,
      useClass: ActivityDynamicGuard,
    },
    {
      provide: DYNAMIC_MAINTENANCE_GUARD_TOKEN,
      useClass: ActivityDynamicMaintenancesGuard,
    },
    { provide: APP_BASE_HREF, useFactory: getBaseUrlFactory },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UserInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UtcDateInterceptor, multi: true },

    { provide: HTTP_INTERCEPTORS, useClass: EncryptorInterceptor, multi: true },

    { provide: GLOBALS_TOKEN, useClass: ActivityGlobals },
    DialogService,
    // NgxTranslateService,
  ],
  bootstrap: [OveraAppComponent],
  entryComponents: [],
})


export class AppModule {

  static injector: Injector;

  constructor(
    private readonly translate: NgxTranslateService,
    private readonly injector: Injector,
    private readonly menuService: RoutesService,
    private readonly goldenLayoutComponentService: GoldenLayoutComponentService,
    private readonly applicationRef: ApplicationRef,
  ) {
    AppModule.injector = this.injector;

    this.translate.setTranslations();
    this.goldenLayoutComponentService.registerComponentTypes(RoutesSettings.Components);
    // if (!environment.production) {
    //   const componentRef = applicationRef.components[0];
    //   enableDebugTools(componentRef);
    // }
  }

  // 
  // ngDoBootstrap() {
  //   AppModule.injector = this.injector;
  // }
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
