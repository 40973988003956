<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <mat-card-content>
                            <div class="container-fluid main-container">
                                <div class="container-individualFilters">
                                    <app-filters #filterCmp [filterInit]=filterInit (loadData)=loadData($event)
                                        (filterChange)=changeFilter($event)
                                        (validAllRequiredFilters)=validAllRequiredFilters($event)>
                                    </app-filters>
                                </div>

                                <div class="row mt-5 mb-5">
                                    <div class="col-12">
                                        <overa-datatable #dataTable [columns]="columns"
                                            [loadDataFunction]="$any(loadDataFunction)"
                                            [localizeLiterals]="datatableLiterals" [showPaginator]="true"
                                            title="Últimas señales" [showGlobalFilter]="true" [rowsPerPage]="[10, 20]"
                                            [showExports]="false" [items]="data" [totalRecords]="totalRecords"
                                            [selectionMode]="'multiple'">
                                        </overa-datatable>
                                    </div>
                                </div>

                            </div>
                        </mat-card-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>